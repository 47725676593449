import React from 'react';
import {Field} from "./Field";


const SettingsCompanyTable = ({settings, getT, handlerChange, is_access_change}) => {
    return (
        <table className={"table  table-sm"}>
            <thead>
            {/*<th>{getT("Фирма")}</th>*/}
            <th>{getT("Параметры системы")}</th>
            {/*<th>{getT("Символ")}</th>*/}
            <th>{getT("Значение")}</th>
            {/*<th>{getT("Описание")}</th>*/}
            {/*<th>{getT("Папка")}</th>*/}

            </thead>
            <tbody>
            {Array.isArray(settings) && settings?.map((item, idx) => {
                return <tr key={idx}>

                    <td>{item?.setting_name}</td>
                    <td>
                        <Field type={item.setting_type}
                               key={item?.setting_id}
                               id={item?.setting_id}
                               // symbol={b.symbol}
                               value={item?.setting_value}
                               disabled={is_access_change}
                               // disabled={!isAccessEdit(Number(idFirm)) || b.symbol === 'html' ? true : false}
                               cb={handlerChange(item)}/>
                        {/*}*/}
                    </td>

                </tr>
            })}
            </tbody>
        </table>
    );
};

export default SettingsCompanyTable;