import React, {useCallback, useEffect, useState} from "react";

const FieldType = {
    number: 'dig',
    text: 'str',
    bool: 'bool',
    html: 'html'
}

export const Field = ({cb, value, type, id = '', disabled}) => {
    const [inputValue, setInputValue] = useState(value);

    // Обработчик изменения значения
    const handleChange = useCallback((e) => {
        let is_boll = type === FieldType.bool;
        const newValue = type === FieldType.bool ? Number(e.target.checked) : e.target.value;
        if (is_boll) {
            cb(newValue)
            return
        }
        setInputValue(newValue);
    }, [type, cb]);
    const handlerSend = () => {
        cb(inputValue)
    }

    useEffect(()=>{
        if(inputValue !== value){
            setInputValue(value)
        }
    }, [value])

    // Опции для полей ввода
    let inputProps = {};
    switch (type) {
        case FieldType.number:
            inputProps = {
                type: "number",
                value: value
            };
            break;
        case FieldType.text:
            inputProps = {
                type: "text",
                value: value,
            };
            break;
        case FieldType.bool:
            inputProps = {
                type: "checkbox",
                value: value
            };
            break;
        case FieldType.html:
            inputProps = {
                type: "text", // HTML обработка через текстовое поле
                value: value,
            };
            break;
        default:
            inputProps = {
                type: "text",
                value: value
            };
            break;
    }
    return (
        <>
            {type === FieldType.bool ? (
                <div className="form-check">
                    <input
                        disabled={disabled}
                        className="form-check-input"
                        id={`field_type_${id}`}
                        onChange={handleChange}
                        type={"checkbox"}
                        checked={!!Number(inputValue)}

                    />
                    <label htmlFor={`field_type_${id}`}>
                    </label>
                </div>
            ) : (
                <div className={"form-group d-flex "} style={{gap: '10px'}}>
                    <input
                        disabled={disabled}
                        className="form-control "
                        onChange={handleChange}
                        type={inputProps.type}
                        value={inputValue}
                    />
                    {value != inputValue && <button onClick={handlerSend} className={"btn btn-success "}><i
                        className="fa fa-paper-plane"></i></button>}
                </div>
            )}
        </>
    );
}