import {instances, URI_API_EXTTUNELL} from "../Salary/setting_api";
import {rejectTransform, resolveTransform} from "../utils_errors";

const ws_goods_retail_price_set = 'ws_goods_retail_price_set'
const ws_goods_retail_price_get = 'ws_goods_retail_price_get'
const ws_goods_retail_price_delete = 'ws_goods_retail_price_delete'

export const WholesaleGoodsRetailPriceAPI={
    getRetailPrice(params) {
        return instances.post(URI_API_EXTTUNELL + '/', null, {
            params: params,
            headers: {
                action: ws_goods_retail_price_get,

            },
        }).then(res => resolveTransform(res))
            .catch(error => rejectTransform(error))
    },
    setRetailPrice(data){
        return instances.post(URI_API_EXTTUNELL + '/', data, {
            headers: {
                action: ws_goods_retail_price_set,
            },
        }).then(res => resolveTransform(res))
            .catch(error => rejectTransform(error))
    } ,
    deleteRetailPrice(data){
        return instances.post(URI_API_EXTTUNELL + '/', data, {
            headers: {
                action: ws_goods_retail_price_delete,
            },
        }).then(res => resolveTransform(res))
            .catch(error => rejectTransform(error))
    }
}