import React from 'react';
import {WHOLESALE_MATERIAL_TYPE_STATUS} from "../../../constans/whole_sale_constants";

const OperationsTab = ({getT, handlerChangeTypeOperationTab, current_tab}) => {
    return (
        <ul className="nav nav-tabs justify-content-center">
            <li className="nav-item" role="presentation">
                <button
                    className={`nav-link ${current_tab === WHOLESALE_MATERIAL_TYPE_STATUS.included ? 'active' : ''}`}
                    type="button" role="tab" aria-selected="true"
                    onClick={handlerChangeTypeOperationTab(WHOLESALE_MATERIAL_TYPE_STATUS.included)}>
                    {getT("Добавить")}
                </button>
            </li>
            <li className="nav-item" role="presentation">
                <button className={`nav-link ${current_tab === WHOLESALE_MATERIAL_TYPE_STATUS.excluded ? 'active' : ''}`}
                                 type="button"
                                 onClick={handlerChangeTypeOperationTab(WHOLESALE_MATERIAL_TYPE_STATUS.excluded)}>
                    {getT("Исключить")}
                </button>
            </li>
        </ul>
    );
};

export default OperationsTab;