import React, {useState} from 'react';
import {LS_FIRM} from "../../../utils/constants";
import Button from "../../../components/ui/Button";

/**
 * ClientTable. таблица заявок
 *
 * @param {array|| null}  connection_clients -  список заявок
 * @param {object|| null}  partner_firm_list - список отповиков
 * @param {array|| null}  company_client_list - список клиентов
 * @param {function}  handlerOpenConfirmRequestModal -  модальное окно для Подтвердждение заявки арг (заявка партнёра)
 * @param {function}  handlerCancelRequestWS -  отменить заявку
 * @param {function} getT -  переводы
 * @returns  {jsx}  -
 */

const ClientTable = ({getT, connection_clients, handlerOpenConfirmRequestModal, handlerCancelRequestWS}) => {
    if (!connection_clients) return <></>
    return (
        <table className="table table-borderless">
            <thead>
            <tr>
                <th scope="col">{getT("Дата создание заявки")}</th>
                <th scope="col">{getT("Фирма")}</th>
                <th scope="col">{getT("Клиент")}</th>
                <th scope="col">{getT("Статус")}</th>
                <th scope="col">{getT("Действие")}</th>
            </tr>
            </thead>
            <tbody>
            {connection_clients?.map((item, index) => {
                let main_firm = Number(item?.main_firm);
                let partner_firm = Number(item?.partner_firm);
                let current_firm = Number(localStorage.getItem(LS_FIRM));
                let is_wholesaler = main_firm === current_firm;

                let is_active = !!Number(item?.active);
                return (<>
                        <tr key={item?.ws_client_connection_id} className={is_wholesaler ? 'table-secondary-light' : 'table-light'}>
                            <td data-label={getT("Дата создание заявки")}>{item?.date}</td>
                            <td data-label={getT("Фирма")}>
                                {is_wholesaler ?
                                    <>{getT("(П)")} {item?.partner_firm_name}</>
                                    : <>{getT("(О)")} {item?.firm_main_name}</>}
                            </td>
                            <td data-label={getT("Клиент")}>{item?.client_name ?? '-'}</td>
                            <td data-label={getT("Статус")}>{
                                is_active ? <span className="badge badge-success">{getT("Подтверждённый")}</span>
                                    : <span className="badge badge-danger">{getT("Неподтверждённый")}</span>
                            }</td>
                            <td data-label={getT("Действие")}>
                                <div className={"d-flex justify-content-end flex-wrap align-items-center"}
                                     style={{gap: "10px"}}>

                                    <Button className={"btn btn-warning"}
                                            onClick={handlerCancelRequestWS(item?.ws_client_connection_id)}>{getT("Отменить")}</Button>

                                    {(!is_active && is_wholesaler) &&
                                        <Button
                                            className={"btn btn-success"}
                                            onClick={handlerOpenConfirmRequestModal(item)}>{getT("Подтвердить")}</Button>
                                    }
                                </div>
                            </td>
                        </tr>
                    </>
                )

            })}
            </tbody>
        </table>
    );
}
    ;

    export default ClientTable;