import {toggleLoaderStatusAC} from "../../reducers/MainReducer";
import {createFormData} from "../../../utils/helpers";
import {WholesaleAdditionalAPI} from "../../../api/Wholesale/WholesaleAdditionalAPI";
import {dispatcherErrorThunk} from "../common_thunk";
import {WholesaleGoodsExceptionsAPI} from "../../../api/Wholesale/WholesaleGoodsExceptionsAPI";
import {wholesaleOperationsWithProductGroupByFirmAC} from "../../actions/WholesaleActions";

async function dispatchedProductsGroupData(firm_id, dispatch) {
    const form_data = createFormData({firm: firm_id})
    const response_material_type_list = await WholesaleAdditionalAPI.getMaterialTypeListByFirm(form_data);
    const response_exceptions = await WholesaleGoodsExceptionsAPI.getException();

    if (response_material_type_list.hasOwnProperty('error') && response_material_type_list?.error) {
        dispatch(dispatcherErrorThunk(response_material_type_list));
        return
    }
    if (response_exceptions.hasOwnProperty('error') && response_exceptions?.error) {
        dispatch(dispatcherErrorThunk(response_exceptions));
        return
    }
    dispatch(wholesaleOperationsWithProductGroupByFirmAC({
        exceptions: response_exceptions?.data,
        material_type_list: response_material_type_list?.data
    }))
}

export const getWsProductGroupsFirmByIdThunk = (firm_id) => async (dispatch) => {
    try {
        dispatch(toggleLoaderStatusAC(true));
        await dispatchedProductsGroupData(firm_id, dispatch)

        dispatch(toggleLoaderStatusAC(false));

    } catch (e) {
        dispatch(dispatcherErrorThunk(e))

    }
}

export const includeExcludeFromTheListOfResaleableGoods = ({new_connect, remove_connect, firm_id}) => async dispatch => {
    try {
        dispatch(toggleLoaderStatusAC(true));
        if(Array?.isArray(remove_connect) && remove_connect?.length){
            let response = await WholesaleGoodsExceptionsAPI.deleteException(remove_connect)
            if (response.hasOwnProperty('error') && response?.error) {
                dispatch(dispatcherErrorThunk(response));
            }
        }
        if(Array?.isArray(new_connect) && new_connect?.length){
            let response =  await WholesaleGoodsExceptionsAPI.setException(new_connect)
            if (response.hasOwnProperty('error') && response?.error) {
                dispatch(dispatcherErrorThunk(response));
            }
        }


        await dispatchedProductsGroupData(firm_id, dispatch)
        dispatch(toggleLoaderStatusAC(false));
    } catch (e) {
        dispatch(dispatcherErrorThunk(e))
    }
}