import React, {useCallback, useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {getWSGoodsRetailPrice, getWSGoodsStockRetailOfFirm} from "../../store/selectors/wholesalary_selector";
import {
    filteredWSGoodsStockRetailOfFirmAndMaterialSearchThunk
} from "../../store/thunks/wholesale/goods_stock_retail_of_firm_thunk";
import PageTitle from "../../components/PageTitle/PageTitle";
import ContainerContent from "../../components/ui/ContainerContent";
import {getMaterialSearchRoot} from "../../store/selectors/material_search_selector";
import {filteredObjectEmptyValues} from "../../utils/helpers";
import GoodsFilterWrap from "../../components/MaterialSearch/Filter/GoodsFilterWrap";

import "../../components/MaterialSearch/material_search_components_style.css"
import GoodsStockList from "./components/GoodsStockList";
import Pagination from "../../components/Pagination/Pagination";
import WSGoodsStockRetailAddModal from "./components/Modal/WSGoodsStockRetailAddModal";
import {getWSGoodsRetailPriceByParamsThunk} from "../../store/thunks/wholesale/goods_retail_price_thunk";
import {LS_FIRM} from "../../utils/constants";
import WSGoodsRetailAddModal from "./components/Modal/WSGoodsRetailPriceAddModal";
import {getWSConnectionClientThunk} from "../../store/thunks/wholesale/connection_client_thunk";

const WsGoodsStockRetailOfFirm = ({getT}) => {
    const dispatch = useDispatch();
    const goods_stock_retail = useSelector(getWSGoodsStockRetailOfFirm);
    const material_search_root = useSelector(getMaterialSearchRoot);
    const goods_retail_price = useSelector(getWSGoodsRetailPrice)


    useEffect(() => {
        dispatch(getWSConnectionClientThunk())
        dispatch(getWSGoodsRetailPriceByParamsThunk({firm_main: localStorage.getItem(LS_FIRM)}))

        dispatch(filteredWSGoodsStockRetailOfFirmAndMaterialSearchThunk({
            type: material_search_root?.filter_values?.type,
            from: 0
        }))
    }, [dispatch]);

    /**
     * goods_stock_retail_dictionary.массив товаров в виде объекта
     *
     * @returns  {null || [product.goods]: product}  -
     */
    const goods_stock_retail_dictionary = useMemo(() => {
        if (!goods_stock_retail || !Array.from(goods_stock_retail)) return null;
        return goods_stock_retail.reduce((acc, product) => {
            acc[product.goods] = product; // добавляем товар в объект, используя id как ключ
            return acc;
        }, {});
    }, [goods_stock_retail]);

    const onDispatchedFilteredData = (data) => {
        dispatch(filteredWSGoodsStockRetailOfFirmAndMaterialSearchThunk(data))
    }
    const handlerFilteredGoods = useCallback((values) => {
        let payload = {
            ...values,
            from: 0
        }
        onDispatchedFilteredData((payload))
    }, [dispatch, material_search_root]);

    const handlerPaginationGoods = useCallback((page) => {
        let result = filteredObjectEmptyValues(material_search_root.filter_values)
        let payload = {
            ...result,
            from: (page - 1) * 30
        }
        onDispatchedFilteredData((payload))
    }, [dispatch, material_search_root])

    const currentPage = useMemo(() => {
        return Math.ceil(material_search_root.pagination.from / 30) + 1;
    }, [material_search_root])
    const goods_retail_price_direction = useMemo(() => {
        if (!goods_retail_price || !Array.isArray(goods_retail_price) || !goods_retail_price?.length) return null
        return goods_retail_price?.reduce((acc, retail_price) => {
            let key = retail_price?.goods;
            let value_of_key = acc?.hasOwnProperty(key) ? [...acc[key], retail_price] : [retail_price];
            return {
                ...acc, [key]: value_of_key
            }
        }, {})

    }, [goods_retail_price]);

    return (
        <ContainerContent>

            <PageTitle title={"ws_goods_stock_retail_of_firm"}/>

            <div className="container-fluid ">
                <div className="row">

                    <div className="col-12 col-md-12">
                        <div className="card p-3">
                            <GoodsFilterWrap key={`goods_stock_filter_wrap`} getT={getT} filter_data={material_search_root?.filter_data}
                                             filter_values={material_search_root?.filter_values}
                                             material_types={material_search_root.material_types}
                                             handlerFilteredGoods={handlerFilteredGoods}
                            />
                        </div>
                        <Pagination key={`pagination_goods_stock_top`} page={currentPage} count={material_search_root?.pagination?.count} pageSize={30}
                                    changeHandler={handlerPaginationGoods}/>
                        <GoodsStockList key={`goods_stock_list`} goods={material_search_root?.goods} getT={getT}
                                        goods_retail_price_direction={goods_retail_price_direction}
                                        goods_stock_retail_dictionary={goods_stock_retail_dictionary}/>
                        <Pagination key={`pagination_goods_stock_bottom`} page={currentPage} count={material_search_root?.pagination?.count} pageSize={30}
                                    changeHandler={handlerPaginationGoods}/>

                    </div>
                </div>
            </div>


            <WSGoodsStockRetailAddModal getT={getT}/>
            <WSGoodsRetailAddModal getT={getT}/>
        </ContainerContent>

    );
};

export default WsGoodsStockRetailOfFirm;