import React, {useEffect, useMemo, useState} from 'react';
import ContainerContent from "../../components/ui/ContainerContent";
import {useDispatch, useSelector} from "react-redux";
import {getStatisticXncOperations} from "../../store/thunks/statistic/statistic_xnc_operations_thunk";
import {
    getStaticsXncOperations,
    getStatisticsIsLoading
} from "../../store/selectors/statistics_xnc_operations_selector";
import StatisticsMainData from "./components/StatisticsMainData";
import StatisticsFull from "./components/Statistics/StatisticsFull";
import StatisticsOperations from "./components/Statistics/StatisticsOperations";
import PageTitle from "../../components/PageTitle/PageTitle";
import {KEYS_NAMING_OPT_STORE} from "../../constans/statistics_xnc_operations_constant";
import Loader from "../../components/Loader/Loader";
import useMonthAndYearDate from "../../hook/useMonthAndYearDate";

const StatisticXncOperationsPage = ({getT}) => {
    const dispatch = useDispatch();
    const statistics_store = useSelector(getStaticsXncOperations);
    const isLoading = useSelector(getStatisticsIsLoading);
    const [filter_date, handlerChangeDateField, parsForParamsData] = useMonthAndYearDate();


    const [form_filter_client_manager, setFormClientManager] = useState({manager: '', client: '', order: ''});
    const onHandlerChangeFormFilterClientManager = (key) => (e) => {

        // let reverse_key_clear = key === 'manager' ? 'client' : 'manager';
        setFormClientManager((prev) => ({
            ...prev,
            manager: '',
            client: '',
            order: '',
            [key]: e.target.value,
        }))
    }
    useEffect(() => {
        // let data_split = filter_date?.split('-').map(Number);
        let params = parsForParamsData()
        dispatch(getStatisticXncOperations(params))

    }, [dispatch, filter_date]);

    const getNameByFilter = useMemo(() => {
        if (!form_filter_client_manager?.client && !form_filter_client_manager?.manager && !form_filter_client_manager?.order) return null;
        let key_for_filter = Object.keys(form_filter_client_manager)?.find(key => !!form_filter_client_manager[key])
        let id_form = form_filter_client_manager?.[key_for_filter];
        let key = KEYS_NAMING_OPT_STORE[key_for_filter].opt_key_info;
        if (key === KEYS_NAMING_OPT_STORE.order.opt) {
            let order_information=  statistics_store?.['orders_client_user']?.find(e=> Number(e?.id) === Number(id_form))
            return `${getT("Менеджер")}: ${order_information?.user}. ${getT("Клиент")}: ${order_information?.client}`
        }
        return statistics_store?.[`${key}`]?.find(e => Number(e?.['id']) === Number(id_form))?.name
    }, [statistics_store, form_filter_client_manager])

    const filteredDataByUser = useMemo(() => {
        if (!form_filter_client_manager?.client && !form_filter_client_manager?.manager && !form_filter_client_manager?.order) return null;
        let key_for_filter = Object.keys(form_filter_client_manager)?.find(key => !!form_filter_client_manager[key])
        let id_form = form_filter_client_manager?.[key_for_filter];
        let key = KEYS_NAMING_OPT_STORE[key_for_filter].opt
        let key_id = KEYS_NAMING_OPT_STORE[key_for_filter].id
        let data_filter_by_id = statistics_store?.[`${key}_data`]
            ?.filter(e => Number(e?.[key_id]) === Number(id_form))
            ?.reduce((acc, item) => {
                let key = item?.op;
                if (key === 'bore' || key === 'paz') {
                    let date_item = {...item, d: item?.diam}
                    let date = acc?.[key] ? [...acc?.[key], date_item] : [date_item];
                    return {
                        ...acc, [key]: date
                    }
                }
                if (key === 'frez') {
                    if (item?.type === "import" || item?.type === "template") {
                        return {
                            ...acc, [key]: {
                                ...acc[key] ?? {}, [item?.type]: item?.count
                            }
                        }
                    }
                    return {
                        ...acc, [key]: item
                    }
                }
                if (key === "trough") {
                    return {
                        ...acc, [key]: {
                            ...acc?.[key] ?? {},
                            [item?.type]: item?.count
                        }
                    }
                }
                return {
                    ...acc, [key]: item?.count
                }

            }, {})
        return data_filter_by_id
    }, [statistics_store, form_filter_client_manager]);
    const uniqueOrders = useMemo(() => {
        if (!statistics_store?.orders_data || !Array.isArray(statistics_store?.orders_data)) return []
        return statistics_store?.orders_data?.reduce((acc, current) => {
            if (!acc.some(order => order.order_id === current.order_id)) {
                acc.push(current);
            }
            return acc;
        }, []);
    }, [statistics_store])

    return (
        <>{isLoading && <Loader/>}
            <ContainerContent>
                <PageTitle title={"statistic_xnc_operations"}/>
                <div className="container-fluid ">
                    <div className="row">
                        <div className="col-12">
                            <div className="card mt-4 px-3 py-2">
                                <div className="form-row  mb-3">
                                    <div className="form-group col-md-6 col-lg-4 col-xl-2">
                                        <label htmlFor="exampleInputEmail1">{getT("Месяц")} / {getT("Год")}</label>
                                        <input type="month" id="month-year" name="month-year" value={filter_date}
                                               className="form-control" onChange={handlerChangeDateField}/>
                                    </div>
                                    <div className="form-group col-md-6 col-lg-4 col-xl-2">
                                        <label htmlFor="exampleInputPassword1">{getT("Клиент")}</label>
                                        <select name="" id="" className={'form-control'}
                                                onChange={onHandlerChangeFormFilterClientManager('client')}
                                                value={form_filter_client_manager.client}>
                                            <option value={''}>------</option>
                                            {statistics_store?.client?.map(e => {
                                                return <option value={e?.id}>{e?.name}</option>
                                            })}
                                        </select>
                                    </div>
                                    <div className="form-group col-md-6 col-lg-4 col-xl-2">
                                        <label htmlFor="exampleInputPassword1">{getT("Менеджер")}</label>
                                        <select name="" id="" className={'form-control'}
                                                onChange={onHandlerChangeFormFilterClientManager('manager')}
                                                value={form_filter_client_manager.manager}>
                                            <option value={''}>------</option>
                                            {statistics_store?.users?.map(e => {
                                                return <option value={e?.id}>{e?.name}</option>
                                            })}
                                        </select>
                                    </div>
                                    <div className="form-group col-md-6 col-lg-4 col-xl-2">
                                        <label htmlFor="exampleInputPassword1">{getT("Заказы")}</label>
                                        <select name="" id="" className={'form-control'}
                                                onChange={onHandlerChangeFormFilterClientManager('order')}
                                                value={form_filter_client_manager.order}>
                                            <option value={''}>------</option>
                                            {uniqueOrders?.map(e => {
                                                return <option value={e?.order_id}>{e?.order_id}</option>
                                            })}
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-lg-6 mb-4">
                            {statistics_store?.all &&
                                <StatisticsMainData getT={getT} date_data={statistics_store?.date_data}
                                                    data={statistics_store?.all}/>}
                        </div>
                        {!!Object?.keys(filteredDataByUser ?? {})?.length && <div className="col-12 col-lg-6 mb-4">
                            <ul className="list-group">
                                <li className={"list-group-item list-group-item-warning d-flex justify-content-between px-1 py-2"}>{getNameByFilter}</li>
                                <StatisticsFull getT={getT} data={filteredDataByUser}/>
                                <StatisticsOperations getT={getT} data={filteredDataByUser}/>
                            </ul>
                        </div>}
                    </div>
                </div>
            </ContainerContent>
        </>
    );
};

export default StatisticXncOperationsPage;