import React from 'react';
import Button from "../../../components/ui/Button";

const ItemWsCompany = ({
                           getT,
                           item,
                           is_connection_partner,
                           handlerCompanyApply,
                           is_awaiting_request,
                           navigateToProductsFirmById
                       }) => {
    let key = `item_company_${item?.firm_id}_${is_connection_partner ? '_1' : '_0'}`;
    let cardCName = `card  ${is_connection_partner ? "border border-success": ""} ${is_awaiting_request ? "border border-warning" : ""}`
    return (
        <div className="col-12 col-md-6 col-xl-3" key={key}>
            <div className={cardCName}>
                <div className="card-header d-flex align-items-center py-2 px-2 align-items-center">
                    <strong className={"m-0   font-weight-bold"}>
                        {item?.firm_id}. {item?.firm_name}
                    </strong>
                    {is_connection_partner ?
                        <>
                            <div className="badge badge-pill badge-success ml-auto">
                                {getT("Подтверждённый")}
                            </div>
                            {/*<Button onClick={handlerCompanyApply}*/}
                            {/*        className={"btn btn-primary btn-sm py-0 px-1  ml-auto"}>{getT("Подать заявку")}</Button>*/}
                        </>
                        :
                        is_awaiting_request ?
                            <div className="badge badge-pill badge-warning ml-auto">
                                {getT("Заявка  отправлена")}
                            </div>
                            : null
                        // <Button onClick={handlerCompanyApply}
                        //         className={"btn btn-primary btn-sm py-0 px-1  ml-auto"}>{getT("Подать заявку")}</Button>
                    }

                </div>
                <div className="card-body py-1 px-2">
                    <p className={"m-0 d-flex justify-content-between"}>
                        <span>{getT("Кол-во товара")}:</span>
                        <span>
                            <strong>{item?.goods_count} </strong>
                            <i className="far fa-eye text-primary cursor-pointer" onClick={navigateToProductsFirmById}> </i>

                                    </span></p>
                    <div className="d-flex justify-content-center">

                    </div>
                </div>
                <div className="card-footer px-2 d-flex justify-content-center flex-wrap" style={{gap: "8px"}}>
                    <Button onClick={navigateToProductsFirmById}
                            className={"btn btn-info btn-sm "}>{getT("Просмотреть товары")}</Button>
                    <Button onClick={handlerCompanyApply}
                            className={"btn btn-success btn-sm " +( is_awaiting_request ? " d-none " : '')}>{getT("Подать заявку")}</Button>
                </div>
            </div>
        </div>
    );
};

export default ItemWsCompany;