import React from "react";
import {Redirect} from "react-router";
import {printHtml} from "../printHtml";
import {LS_FIRM, LS_LANG, LS_USER_ID} from "../utils/constants";

class ApiService extends React.Component {
    //TODO: ссылка под замену (АПИ)
    constructor() {
        super();
        this._urlDefApi = 'api.ifurn.pro/api';
        this._urlIfurnApi = 'api.ifurn.pro';
        this._urlDefProject = 'newgo.ifurn.pro/project/';
        this.__baseUrlNG = 'newgo.ifurn.pro/api/';
        this._urlDefGl = 'gl.ifurn.pro';
        this._urlDefMain = 'go.ifurn.pro';
        this._openFileDefApi = 'd.ifurn.pro?';
        let hostname = window.location.hostname,
            delimiter = hostname.match('-'),
            protocol = window.location.protocol;
        if(hostname.includes('ifp')){
            this._baseUrl = 'http://local-api.ifp.com/api';
            this._baseIfurn = 'http://local-api.ifp.com';
            this._baseUrlProject = 'http://local-newgo.ifp.com/project/';
            this.__baseUrlNG = 'http://local-img.ifp.com/';
            this._glLink = 'http://local-gl.ifp.com';
            this._mainLink = 'https://go2.ifurn.pro';
            this._openFileRequest = "http://" + "local-d.ifp.com?";
        }
         else if (delimiter) {
            if (delimiter.input.match(/(\d+)/g)) {
                this._baseUrl = protocol + "//" + hostname.substr(0, delimiter.index) + "-" + "api2.ifurn.pro/api";
                this._baseIfurn = protocol + "//" + hostname.substr(0, delimiter.index) + "-" + "api2.ifurn.pro";
                this._baseUrlProject = protocol + "//" + hostname.substr(0, delimiter.index) + "-" + "newgo2.ifurn.pro/project/";
                this.__baseUrlNG = protocol + "//" + hostname.substr(0, delimiter.index) + "-" + "newgo2.ifurn.pro/api/";
                this._glLink = protocol + "//" + hostname.substr(0, delimiter.index) + "-" + "gl2.ifurn.pro";
                this._mainLink = protocol + "//" + hostname.substr(0, delimiter.index) + "-" + "go2.ifurn.pro";
                this._openFileRequest = protocol + "//" + hostname.substr(0, delimiter.index) + "-" + "d2.ifurn.pro?";
            } else {
                this._baseUrl = protocol + "//" + hostname.substr(0, delimiter.index) + "-" + this._urlDefApi;
                this._baseIfurn = protocol + "//" + hostname.substr(0, delimiter.index) + "-" + this._urlIfurnApi;
                this._baseUrlProject = protocol + "//" + hostname.substr(0, delimiter.index) + "-" + this._urlDefProject;
                this.__baseUrlNG = protocol + "//" + hostname.substr(0, delimiter.index) + "-" + this.__baseUrlNG;
                this._glLink = protocol + "//" + hostname.substr(0, delimiter.index) + "-" + this._urlDefGl;
                this._mainLink = protocol + "//" + hostname.substr(0, delimiter.index) + "-" + this._urlDefMain;
                if (delimiter.input.includes('test')) {
                    this._openFileRequest = protocol + "//" + "d2.ifurn.pro?";
                } else {
                    this._openFileRequest = protocol + "//" + hostname.substr(0, delimiter.index) + "-" + this._openFileDefApi;
                }
            }
            // this._openFileRequest = "https://" + hostname.substr(0, delimiter.index) + "-" + this._openFileDefApi;

        } else {
            if (hostname.includes("localhost") || hostname.includes('ifurn.local') || hostname.includes('abris-ifurn.local') || hostname.includes('crm2.ifurn.pro.loc')) {
                this._baseUrl = 'https://api2.ifurn.pro/api';
                this._baseIfurn = 'https://api2.ifurn.pro';
                this._baseUrlProject = 'https://newgo2.ifurn.pro/project/';
                this.__baseUrlNG = 'https://newgo2.ifurn.pro/api/';
                this._glLink = 'https://gl2.ifurn.pro';
                this._mainLink = 'https://go2.ifurn.pro';
                this._openFileRequest = "https://" + "d2.ifurn.pro?";

            } else if (hostname.match(/(\d+)/g)) {
                this._baseUrl = protocol + '//api2.ifurn.pro/api';
                this._baseIfurn = protocol + '//api2.ifurn.pro';
                this._baseUrlProject = protocol + '//newgo2.ifurn.pro/project/';
                this.__baseUrlNG = protocol + '//newgo2.ifurn.pro/api/';
                this._glLink = protocol + '//gl2.ifurn.pro';
                this._mainLink = protocol + '//go2.ifurn.pro';
                this._openFileRequest = protocol + "//" + "d2.ifurn.pro?";
            } else {
                this._baseUrl = protocol + '//' + this._urlDefApi;
                this._baseIfurn = protocol + "//" + this._urlIfurnApi;
                this._baseUrlProject = 'https://' + this._urlDefProject;
                this.__baseUrlNG = 'https://' + this.__baseUrlNG;
                this._glLink = protocol + '//' + this._urlDefGl;
                this._mainLink = protocol + '//' + this._urlDefMain;
                this._openFileRequest = protocol + "//" + "d2.ifurn.pro?";

            }
        }
        // console.log(this._baseUrl)
        // console.log(this._glLink)
        // console.log(this._mainLink)
    }

    // _baseUrl = "https://test-api.ifurn.pro/api";
    // _baseUrl = "https://test2-api.ifurn.pro/api";
    // _baseUrl = "https://api.ifurn.pro/api";
    // _baseUrl = "http://api-local.ifurn.pro/api";
    // _baseUrl = "https://ifurnapi.kvk-dev.pp.ua/api";
    _apiKey = 123456789;

    async _getData(url) {
        try {
            const res = await fetch(`${this._baseUrl}${url}`, {
                // referrerPolicy: 'unsafe-url',
                // mode: "cors",
                cache: "no-cache",
                credentials: "include",
                referrerPolicy: 'unsafe-url',
                headers: {
                    'X-Api-Key': this._apiKey,
                    'Language': localStorage.getItem(LS_LANG) || 'ua',

                },
            });

            let urlRedirect = '';
            for (let entry of res.headers.entries()) {
                if (entry[0] === 'correct-server') {
                    urlRedirect = entry[1];
                }
            }
            for (let entry of res.headers.entries()) {
                if (entry[0] === 'auth-token') {
                    urlRedirect += '?auth_token=' + entry[1];
                }
            }

            if (!!urlRedirect) {
                window.location.href = urlRedirect;
                return
            }
            const body = await res.json();

            return body;
        } catch (error) {
            console.log(error);
            return  {
                custom_error: error?.message,
                base_url: url
            }

        }
    }

    async _sendData(url, method, data, resType = "json") {
        let body = null;
        const res = await fetch(`${this._baseUrl}${url}`, {
            method: method,
            mode: "cors",
            cache: "no-cache",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
                Firm: localStorage.getItem(LS_FIRM),
                "Authorization": "Basic U2VydmljZUlGUDpNVFU1TnpVeg=='",
                'X-Api-Key': this._apiKey,
                'Language': localStorage.getItem(LS_LANG) || 'ua',
            },
            redirect: "follow",
            // referrerPolicy: "no-referrer",
            referrerPolicy: 'unsafe-url',
            body: JSON.stringify(data)
        });


        let urlRedirect = '';
        for (let entry of res.headers.entries()) {
            if (entry[0] === 'correct-server') {
                urlRedirect = entry[1];
            }
        }
        for (let entry of res.headers.entries()) {
            if (entry[0] === 'auth-token') {
                urlRedirect += '?auth_token=' + entry[1];
            }
        }

        if (resType === "json") {
            body = await res.json();
        }
        if (resType === "text") {
            body = await res.text();
        }
        if (!!urlRedirect) {
            window.location.href = urlRedirect;
        }

        return body;
    }

    async _sendFileData(url, files, data = null, firm = localStorage.getItem("i_furn_user_firm")) {

        const formData = new FormData();

        formData.append('firm', firm);

        for (let i = 0; i < files.length; i++) {
            formData.append('import_porject_' + i, files[i])
        }

        if (data) {
            data.forEach(e => {
                formData.append(e.name, e.value);
            })
        }

        const res = await fetch(`${this._baseUrl}${url}`, {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'include',
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: formData
        });

        const body = await res.json();

        if (body.error_api) {
            this._errors = body.error_api;
            return false;
        }

        return body;

    }

    getAuth = async (login, pass) => {
        const res = await this._getData(
            `/users/filter/?email=${login}&pass=${pass}`
        );
        if (
            res &&
            res.count > 0 &&
            res.data &&
            Number(res.data.active) === 1 &&
            res.data.user_data.roles &&
            res.data.user_data.roles.length > 0
        ) {
            return this._transformAuth(res.data);
        } else {
            return false;
        }
    };
    logoutCabinet = async () => {
        let res = await this._sendData('/authn', "DELETE");
        return res;
    }
    userVerification = async (url = '/authn') => {
        return await this._getData(url, "GET")
    }
    getAuthData = async (user_id) => {
        if (!user_id) {
            return false;
        }
        // debugger;
        const res = await this._getData(`/users/${user_id}`);
        if (res && Number(res.active) === 1) {
            return this._transformAuth(res);
        } else {
            return false;
        }
    };

    _transformAuth = (data) => {
        localStorage.setItem(LS_FIRM, data.firm);
        localStorage.setItem(LS_USER_ID, data.user_data.user_id);
        return {
            id: data.user_data.user_id,
            name: data.name,
            firm: data.firm,
            email: data.email,
            phone: data.phone,
            role: data.user_data.roles
        };
    };

    getUsers = async () => {
        const res = await this._sendData(
            `/users/?get_firm=${localStorage.getItem("i_furn_user_firm")}`,
            "POST",
            {
                get_firm: localStorage.getItem("i_furn_user_firm")
            }
        );

        if (res.count > 0) {
            return this._transformUsers(res);
        } else {
            return false;
        }
    };

    _transformUsers = (res) => {
        return res.data.map((e) => {
            return {
                id: e.user_id,
                name: e.name
            };
        });
    };

    getOrders = async (archive, filters) => {
        let filter_string = "";

        if (filters) {
            filter_string += filters.id ? "&id=" + filters.id : "";
            filter_string += filters.code ? "&code=" + filters.code : "";
            // filter_string += filters.date ? "&date=" + filters.date : "";
            filter_string += filters.date
                ? "&date_from=" + filters.date.from + "&date_to=" + filters.date.to
                : "";
            filter_string += filters.name ? "&name=" + filters.name : "";
            filter_string += filters.status ? "&status=" + filters.status : "";
            filter_string += filters.client ? "&client=" + filters.client : "";
            filter_string += filters.date_production
                ? "&plan_of_production_from=" +
                filters.date_production.from +
                "&plan_of_production_to=" +
                filters.date_production.to
                : "";
            filter_string +=
                filters.manager || filters.manager === 0
                    ? "&user=" + filters.manager
                    : "";
            filter_string +=
                filters.limit || filters.limit === 0 ? "&limit=" + filters.limit : "";
            filter_string +=
                filters.type || filters.type === 0 ? "&type=" + filters.type : "";
            filter_string += filters.code1c ? "&code1c=" + filters.code1c : "";

            filter_string += filters.error1c ? "&error1c=" + filters.error1c : "";
            filter_string += filters.production_stage_number
                ? "&production_stage_number=" + filters.production_stage_number
                : "";
            filter_string += filters.summ_order
                ? "&summ_order=" + filters.summ_order
                : "";
            filter_string += filters.order_out_of_date
                ? "&order_out_of_date=" + filters.order_out_of_date
                : "";
            filter_string += filters.collected
                ? "&collected=" + filters.collected
                : "";
            filter_string += filters.material_type
                ? "&material_type=" + filters.material_type
                : "";
            filter_string += filters.service_type
                ? "&service_type=" + filters.service_type
                : "";
        }

        const res = await this._getData(
            `/orders/filter/?firm=${localStorage.getItem(
                "i_furn_user_firm"
            )}&archive=${archive}${filter_string}`
        );
        console.log(res)
        if (res && res.error_api) {
            localStorage.removeItem("i_furn_user_id");
            localStorage.removeItem("i_furn_user_firm");
            <Redirect to="/"/>;
        } else if (res && res.count > 0) {
            return this._transformOrders(res);
        }else if(res && res.custom_error){
            return  res.custom_error
        }
    };

    getStatistics = async () => {
        const res = await this._getData(
            `/orders/filter/?firm=${localStorage.getItem(
                "i_furn_user_firm"
            )}&start_data=on`
        );

        if (res) {
            return this._transformStatictics(res);
        } else {
            return false;
        }
    };
    getTermsDashboard = async () => {
        const res = await this._getData(
            `/orders/filter/?firm=${localStorage.getItem(
                "i_furn_user_firm"
            )}&start_data=on`
        );
        return res ? res : false;
    };
    getNewClientsDashboard = async () => {
        const res = await this._getData(
            `/orders/filter/?firm=${localStorage.getItem(
                "i_furn_user_firm"
            )}&start_data=on`
        );
        return res ? res : false;
    };
    getStatisticsSingle = async () => {
        const res = await this._getData(
            `/orders/filter/?firm=${localStorage.getItem(
                "i_furn_user_firm"
            )}&user_id=${localStorage.getItem("i_furn_user_id")}&start_data=on`
        );
        if (res) {
            return this._transformStatictics(res);
        } else {
            return false;
        }
    };

    _transformStatictics = (res) => {
        if (res && res.orders > 0) {
            return {
                all_summ: res.all_summ,
                orders: res.orders,
                goods: res.date_sale.goods,
                serv: res.date_sale.service,
                orders_with_xnc: res.orders_with_xnc,
                service_sale: res.service_sale,
                service_type: res.service_type,
                terms: res.terms,
                new_clients: res.new_clients
            };
        } else {
            return false;
        }
    };

    _transformOrders = (res) => {
        let data = res.data;

        return data.map((e) => {
            return {
                count_detail: e?.count_detail,
                currency: e?.currency || '',
                count: res.count,
                summ_order: e?.summ_order || '',
                calc: e?.calc || {},
                count_all: res.count_all,
                urgent: e.urgent,
                id: e.id,
                payment_status: e.payment_status,
                mat: e.data_type.mat,
                serv: e.data_type.serv,
                timeline: e.timeline,
                client_DB: e.client_DB,
                name: e.name,
                code: e.code,
                code1c: e.code1c,
                adress_delivery: e.adress_delivery,
                status: e.status,
                manager: e.manager,
                client: {
                    id: e.client,
                    name: e.confirmed
                        ? e.client_surname + " " + e.client_name + " " + e.client_middlename
                        : e.client_base_name,
                    name_main: e.client_base_name
                },
                xnc_operations: e.have_operations,
                last_update: e.d_update,
                date_production: e.plan_of_production
                    ? e.plan_of_production
                    : "Не указано",
                projects: e.projects,
                confirm: {
                    isConfirmed: e.confirmed,
                    email: e.confirmed_email,
                    cost: e.confirmed_cost
                },
                status_code: e.status_code,
                firm: e.firm,
                last_project:
                    e.projects && e.projects.length > 0 ? e.projects[0].version : ""
            };
        });
    };
    getMaterialsFilters = async () => {
        const res = await this._getData("/settings/1");

        if (res.status === "good") {
            return this._transformMaterialsFilters(res[0]);
        } else {
            return false;
        }
    };
    getSettings = async () => {
        const firm = localStorage.getItem("i_furn_user_firm");
        const res = await this._getData(`/settings/${firm}`);

        if (res) {
            localStorage.setItem(
                "xnc",
                JSON.parse(res[0].production["1c.permit_status_3_without_rules"])
            );
            return res;
        }
        // return res[0].production
    };

    _transformMaterialsFilters = (res) => {
        return res.type_material.data.map((e) => {
            return {
                material_type_id: e.material_type_id,
                name: {
                    ru: e.ru,
                    ua: e.ua,
                    en: e.en,
                    pl: e.pl,
                    he: e.he,
                    ro: e.ro
                }
            };
        });
    };

    getServiceFilters = async () => {
        const res = await this._getData("/settings/1");

        if (res.status === "good") {
            return this._transformServiceFilters(res[0]);
        } else {
            return false;
        }
    };

    _transformServiceFilters = (res) => {
        return res.type_serv.data.map((e) => {
            return {
                goods_service_type_id: e.goods_service_type_id,
                name: {
                    ru: e.ru,
                    ua: e.ua,
                    ro: e.ro,
                    pl: e.pl,
                    en: e.en,
                    he: e.he
                }
            };
        });
    };
    getProductionTask = async (type, getCounts=null) => {
        let type_req;
        switch (type) {
            case "for":
                type_req = 3;
                break;
            case "in":
                type_req = 4;
                break;
            case "complete":
                type_req = 5;
                break;
            case "forShipping":
                type_req = 6;
                break;
        }
        const res = await this._getData(
            `/orders/filter/?firm=${localStorage.getItem(
                "i_furn_user_firm"
            )}&status=${type_req}`
        );

        if (res.error_api) {
            localStorage.removeItem("i_furn_user_id");
            localStorage.removeItem("i_furn_user_firm");
            <Redirect to="/"/>;

            return false;
        } else {
           if(getCounts ) getCounts({count: res?.count, count_all:res?.count_all})
            return res.count > 0
                ? this._transformProductionsTask(res.data, type)
                // {
                //     count: res.count,
                //     count_all: res?.count_all,
                //     data:
                // }
                : [];
        }
    };

    _transformProductionsTask = (data, type) => {
        /// TODO: Сделать date и user для comlete типа
        let type_info = {type_info: "transparent"};
        return data.map((e) => {
            return {
                isChecked: false,
                manager: e?.manager ?? null,
                payment_status: e.payment_status,
                production_task: e.production_task,
                parent: e.parent,
                edge_type: e.edge_type,
                glue_type: e.glue_type !== null ? e.glue_type : [type_info],
                place_in_order_to_pack: e.place_in_order_to_pack,
                goods_order_without_part: e.goods_order_without_part,
                urgent: e.urgent,
                mat: e.data_type?.mat ?? [],
                serv: e.data_type?.serv,
                timeline: e.orders_goods,
                pallete: e.pallete,
                summ_order: e.summ_order,
                service_summ: e.service_summ,
                client_DB: e.client_DB,
                order_out_of_date: e.order_out_of_date,
                id: e.id,
                name: e.name,
                code: e.code,
                programm_make: e.programm_make,
                calc: e.calc,
                adress_delivery: e.adress_delivery,
                status: e.status,
                confirmed_date: e.confirmed_date,
                plan_of_production: e.plan_of_production,
                client: {
                    id: e.client,
                    name: e.client_base_name
                },
                date: type === "for" ? e.plan_of_production : e.in_production,
                user:
                    type === "for" ? e.changed_plan_of_production : e.in_production_user,
                last_update: e.d_update,
                adress: {
                    production: e.adress_production
                },
                production_stage_number: e.production_stage_number,
                services_status: e.production_status.map((s) => {
                    return {
                        id: s.orders_goods_id,
                        name: s.name,
                        status: s.service_ready
                    };
                })
            };
        });
    };

    getOrder = async (id) => {
        const res = await this._getData(`/orders/${id}`);

        if (res) {
            let order_data = this._transformOrder(res);
            let project_data = await this._getData(
                `/projects/${order_data.last_project}/?client=${order_data.client.id}&order=${order_data.code}`
            );
            order_data.project_data = project_data.order;
            order_data.calculate_types = project_data.order?.calculate_types;
            return Promise.resolve(order_data)
        } else {
            return Promise.resolve(false)
        }
    };

    _transformOrder = (data) => {
        debugger;
        return {
            code1c: data.code1c,
            id: data.id,
            name: data.name,
            code: data.code,
            adress_delivery: data.adress_delivery,
            code_reg: data.reg_code,
            status: data.status,
            comment: data.comment,
            file_from_user: data.file_from_user,
            manager: {
                id: data.manager.user_id,
                name: data.manager.name
            },
            currency: {
                id: data.currency,
                name: data.currency_name
            },
            date: data.date,
            client: {
                id: data.client,
                name: data.confirmed
                    ? data.client_surname ?? "" +
                    " " +
                    data.client_name ?? " " +
                    " " +
                    data.client_middlename ?? ""
                    : data.client_base_name ?? "",
                name_main: data.client_base_name,
                phone: data.client_phone_main,
                phone_questions: data.client_phone_questions ?? "",
                phone_sms: data.client_phone_sms ?? ""
            },
            xnc_operations: data.have_operations,
            last_update: data.d_update,
            confirm: {
                isConfirmed: data.confirmed,
                email: data.confirmed_email,
                cost: data.confirmed_cost
            },
            adress: {
                delivery: data.adress_delivery,
                store: data.adress_store,
                sale: data.adress_sale,
                production: data.adress_production,
                delivery_method: data.delivery_method
            },
            messages: data.messages ? data.messages : [],
            calculate: data.calculate,
            last_project: data.projects[0].version,
            calculate_types: [],
            services_ready: data.services_ready,
            count_detail: data?.count_detail

        };
    };

    updateOrder = async (order_id, data) => {
        let res = await this._sendData("/orders/" + order_id, "PUT", {
            ...data,
            user: localStorage.getItem("i_furn_user_id")
        });

        return res;
    };

    updateProductionTask = async (order_id, data) => {
        let res = await this._sendData("/production-task/" + order_id, "PUT", data);
        return res;
    };

    getClients = async (filter = {}, firm= localStorage.getItem("i_furn_user_firm")) => {
        let url =
            "/clients/filter/?firm="+ firm ;


        if (Object.keys(filter).length > 0) {
            url +=
                filter.name && filter.name.length > 0 ? "&c_name=" + filter.name : "";
            url +=
                filter.phone && filter.phone.length > 0
                    ? "&c_phone=" + filter.phone
                    : "";
            url += "&limit=14";
        }
        const res = await this._getData(url);
        if (res.error_api) {
            localStorage.removeItem("i_furn_user_id");
            localStorage.removeItem("i_furn_user_firm");
            <Redirect to="/"/>;
            return false;
        } else {
            return res ? this._transformClients(res.data) : [];
        }
    };

    _transformClients = (data) => {
        return data.map((e) => {
            return {
                id: e.client_id,
                name: e.name,
                email: e.email,
                active: e.active,
                phone: e.phone,
                type_input: e.type_input
            };
        });
    };

    getClient = async (id) => {
        const res = await this._getData("/clients/" + id);
        return res ? this._transformClient(res) : false;
    };

    _transformClient = (data) => {
        return {
            id: data.client_id,
            name: data.name,
            email: data.email,
            adress: data.adress,
            active: Number(data.active),
            phone: data.phone,
            type_input: data.type_input
        };
    };

    getNotify = async () => {
        const res = await this._getData(
            "/notify/" + localStorage.getItem("i_furn_user_id")
        );
        return res ? res.data : false;
    };

    updateClient = async (client_id, data) => {
        let res = await this._sendData("/clients/" + client_id, "PUT", data);
        return res;
    };

    updateNotify = async (notify_id) => {
        let res = await this._sendData("/notify/" + notify_id, "PUT", {
            user: localStorage.getItem("i_furn_user_id")
        });
        return res;
    };

    getOrdersCounts = async () => {
        const res = await this._getData(
            "/orders/counts/?firm=" + localStorage.getItem("i_furn_user_firm")
        );

        return res ? res.data : false;
    };


    getPDF = async (action, order, message = '') => {
        try {
            const ACTIONS_BLANK_SHORT = {
                'order_blank_short_without_html': 'order_blank_short_only_html',
                'order_blank_short_without': 'order_blank_short_only',
            }
            let print_array = [];
            const data = {
                action: action,
                order: order,
                print_array: print_array

            }

            if (action === "order_blank_short_without" || action === 'order_blank_short_without_html') {
                order.calculate = [];
                order.calculate_discounts = [];
                order.calculate_types = [];
                data.action = ACTIONS_BLANK_SHORT[action];
            }

            if (action === "merge") {
                data.print_array = order.print_array;
            }
            if (action === "renew_cards_crm") {
                data.message = message
            }
            const res_calc = await this._sendData(`/giblab`, "POST", {
                ...data
            })

            if (
                (res_calc.response && res_calc.response.res === false) ||
                (res_calc.response && res_calc.response.error)
            ) {
                return res_calc.response;
            }
            if (res_calc.error_api) {
                return {
                    error_api: {
                        mysql: res_calc.error_api.mysql,
                        api: res_calc.error_api.api_error
                    }
                };
            }
            if (res_calc.res) {
                setTimeout(function () {
                    document.location.reload();
                }, 1500);
                return {
                    status: res_calc.res
                };
            }
            if (res_calc.hasOwnProperty('base')) {
                switch (action) {
                    case 'material_list_html': {
                        return printHtml.renderBase64ToHtmlWindow(res_calc.base, `(${order?.code}/${order?.id})`, order);
                    }
                    case 'order_blank_short_only_html': {
                        return printHtml.renderBase64ToHtmlWindow(res_calc.base, `(${order?.code}/${order?.id})`, order);
                    }
                    case 'order_blank_short_html': {
                        return printHtml.renderBase64ToHtmlWindow(res_calc.base, `(${order?.code}/${order?.id})`, order);
                    }
                    case 'order_blank_short_without_html': {
                        return printHtml.renderBase64ToHtmlWindow(res_calc.base, `(${order?.code}/${order?.id})`, order);
                    }
                    default: {
                        console.log('action not found', action);
                    }
                }

            }
            if (res_calc.response) {
                switch (action) {
                    case "cards":
                        res_calc.response.files.forEach((e, i) => {
                            if (i !== 0) {
                                window.open(e.link);
                            }
                        });
                        break;
                    case "xnc":
                        setTimeout(() => {
                            window.open(res_calc.response.files[1].link, '_blank')
                        }, 0);
                        break;
                    case "pdf":
                        setTimeout(() => {
                            window.open(res_calc.response.files[1].link, '_blank')
                        }, 0);
                        break;

                    default:
                        setTimeout(() => {
                            window.open(res_calc.response.link, '_blank')
                        }, 0);
                }
            }
        }
        catch (e) {
            console.log(e)
            return  Promise.reject(e)
        }
    };

    getContentHtmlFile = async (action, order) => {
        const ACTIONS_BLANK_SHORT = {
            'order_blank_short_without_html': 'order_blank_short_only_html',
            'order_blank_short_without': 'order_blank_short_only',
        }
        let print_array = [];
        const data = {
            action: action,
            order: order,
            print_array: print_array

        }

        if (action === 'order_blank_short_without_html') {
            order.calculate = [];
            order.calculate_discounts = [];
            order.calculate_types = [];
            data.action = ACTIONS_BLANK_SHORT[action];
        }


        const res_calc = await this._sendData(`/giblab`, "POST", {
            ...data
        });
        if (
            (res_calc.response && res_calc.response.res === false) ||
            (res_calc.response && res_calc.response.error)
        ) {
            return res_calc.response;
        }
        if (res_calc.error_api) {
            return {
                error_api: {
                    mysql: res_calc.error_api.mysql,
                    api: res_calc.error_api.api_error
                }
            };
        }
        return res_calc
    }
    createClient = async (form) => {
        return await this._sendData(`/clients`, "POST", {
            name: form.name,
            phone: form.phone,
            email: form.email,
            adress: form.adress,
            firm: localStorage.getItem("i_furn_user_firm")
        });
    };

    editClient = async (client_id, data) => {
        let res = await this._sendData("/clients/" + client_id, "PUT", data);
        return res;
    };

    getCalculate = async (discounts, order) => {
        return await this._sendData(`/calculate`, "POST", {
            calc: null,
            discounts: discounts,
            materials: order.calculate_types,
            order: order
        });
    };

    updateProject = async (order, version) => {
        return await this._sendData(`/projects/` + version, "PUT", {
            order: order
        });
    };

    confirmOrder = async (order, total) => {
        return await this._sendData(`/confirm`, "POST", {
            amount: total,
            calculate: order.calculate,
            currency: order.currency,
            data: order.confirm_data,
            extra: order.extra,
            materials: order.material,
            order_code: order.code
        });
    };

    createOrder = async (client_id, title) => {
        return await this._sendData(`/orders/`, "POST", {
            client: client_id,
            firm: localStorage.getItem("i_furn_user_firm"),
            user: localStorage.getItem("i_furn_user_id"), title: title
        });
    };

    updateProductionStatus = async (service_id, status) => {
        return await this._sendData(`/orders/`, "POST", {
            set_production_status: "1",
            id: service_id,
            status: status,
            user: localStorage.getItem("i_furn_user_id")
        });
    };

    updateOrderProductionDate = async (order_id, date) => {
        return await this._sendData(`/orders/`, "POST", {
            order_change_property: "date_production",
            order_id: order_id,
            date: date,
            user_id: localStorage.getItem("i_furn_user_id")
        });
    };

    updateProductionTaskProductionDate = async (order_id, date) => {
        return await this._sendData(`/production-task/${order_id}`, "PUT", {
            plan_of_production: date
        });
    };

    updateOrderProductionStage = async (order_id, stage) => {
        return await this._sendData(`/orders/`, "POST", {
            order_change_property: "order_production_stage",
            order_id: order_id,
            stage: stage
        });
    };

    getReport = async (type, dates) => {
        return await this._sendData(`/report/`, "POST", {
            firm: localStorage.getItem("i_furn_user_firm"),
            type: type,
            date_from: dates.date_from,
            date_to: dates.date_to,
            lang: localStorage.getItem('lang') || "ru",
            paymount: dates?.paymount ?? '',

            currency: dates.currency,
            manager: dates.manager
        });
    };

    getCurrency = async () => {
        let currency = [
            {name: "usd", id: 1},
            {name: "uah", id: 2},
            {name: "eur", id: 3},
            {name: "nis", id: 4},
            {name: "pln", id: 5},
            {name: "mdl", id: 6}
        ];
        return currency;
    };

    getManagers = async () => {
        let url = "/managers/" + localStorage.getItem("i_furn_user_firm");
        const res = await this._getData(url);
        if (res.error_api) {
            localStorage.removeItem("i_furn_user_id");
            localStorage.removeItem("i_furn_user_firm");
            < Redirect to="/"/>;
            return false;
        } else {
            return res ? this._transformManagers(res.data) : [];
        }
    };

    _transformManagers = (data) => {
        return data.map((e) => {
            return {
                id: e.user_id,
                name: e.name,
                email: e.email,
                phone: e.phone
            };
        });
    };

    createProductionTask = async (prodOrders) => {
        return await this._sendData(`/production-task`, "POST", {
            action: prodOrders.action,
            orders: prodOrders.orders,
            name: prodOrders.name,
            task: prodOrders.task,
            manager: prodOrders.manager
        });
    };

    getProductionTaskType = async (filters = false) => {
        let filter_string = "";

        if (filters) {
            filter_string += filters.id ? "&id=" + filters.id : "";
            filter_string += filters.date
                ? "&date_from=" + filters.date.from + "&date_to=" + filters.date.to
                : "";
            filter_string += filters.name ? "&name=" + filters.name : "";
            filter_string += filters.status ? "&status=" + filters.status : "";
            filter_string += filters.date_production
                ? "&plan_of_production_from=" +
                filters.date_production.from +
                "&plan_of_production_to=" +
                filters.date_production.to
                : "";

            filter_string +=
                filters.limit || filters.limit === 0 ? "&limit=" + filters.limit : "";
        }

        const res = await this._getData(`/production-task/?${filter_string}`);

        return res ? res : [];
    };

    getDashboardType = async (filters = false) => {
        let filter_string = "";

        if (filters) {
            filter_string += filters.status ? "&status=" + filters.status : "";
            filter_string += filters.date_prev
                ? "&date_prev=" + filters.date_prev
                : "";
            filter_string += filters.day_num ? "&day_num=" + filters.day_num : "";
            filter_string += filters.manager ? "&user_id=" + filters.manager : "";
        }

        const res = await this._getData(
            `/orders/filter/?firm=${localStorage.getItem(
                "i_furn_user_firm"
            )}&start_data=on${filter_string}`
        );
        return res ? this._transformStatictics(res) : [];
    };

    getDashboardSingle = async (filters = false) => {
        let filter_string = "";
        debugger;
        if (filters) {
            filter_string += filters.status ? "&status=" + filters.status : "";
            filter_string += filters.date_prev
                ? "&date_prev=" + filters.date_prev
                : "";
            filter_string += filters.day_num ? "&day_num=" + filters.day_num : "";
            filter_string += filters.manager
                ? "&user_id=" + localStorage.getItem("i_furn_user_id")
                : "";
        }

        const res = await this._getData(
            `/orders/filter/?firm=${localStorage.getItem(
                "i_furn_user_firm"
            )}&start_data=on${filter_string}`
        );
        debugger;

        return res ? this._transformStatictics(res) : [];
    };

    getProductionSingleTask = async (id) => {
        const res = await this._getData(`/production-task/${id}`);

        if (res.childs && res.childs.length > 0) {
            let order_data = this._transformProductionOrder(res);
            let project_data = await this._getData(
                `/projects/${order_data.last_project}/?client=${order_data.client.id}&order=${order_data.code}`
            );

            order_data.project_data = project_data.order;
            order_data.calculate_types = project_data.order.calculate_types;
            return order_data;
        } else if (res?.error_api) {
            return res?.error_api
        } else {
            return false;
        }
    };
    _transformProductionOrder = (data) => {
        return {
            childs: data.childs,
            code1c: data.code1c,
            id: data.id,
            name: data.name,
            code: data.code,
            adress_delivery: data.adress_delivery,
            code_reg: data.reg_code,
            status: data.status,
            comment: data.comment,
            // manager: {
            //   id: data.manager.user_id,
            //   name: data.manager.name
            // },
            currency: {
                id: data.currency,
                name: data.currency_name
            },
            date: data.date,
            client: {
                id: data.client,
                name: data.confirmed
                    ? data.client_surname +
                    " " +
                    data.client_name +
                    " " +
                    data.client_middlename
                    : data.client_base_name,
                name_main: data.client_base_name,
                phone: data.client_phone_main,
                phone_questions: data.client_phone_questions,
                phone_sms: data.client_phone_sms
            },
            xnc_operations: data.have_operations,
            last_update: data.d_update,
            confirm: {
                isConfirmed: data.confirmed,
                email: data.confirmed_email,
                cost: data.confirmed_cost
            },
            adress: {
                delivery: data.adress_delivery,
                store: data.adress_store,
                sale: data.adress_sale,
                production: data.adress_production,
                delivery_method: data.delivery_method
            },
            messages: data.messages ? data.messages : [],
            calculate: data.calculate,
            last_project: data.projects[0].version,
            calculate_types: [],
            services_ready: data.services_ready
        };
    };
    getProductionTasksByMaterials = async () => {
        const res = await this._getData(`/production-task/orders`);

        if (res && res.length > 0) {
            // return res.length > 0 ? res : [];
            return this._transformProductionTasksByMaterials(res);
        }
    };
    _transformProductionTasksByMaterials = (data) => {
        return data.map((el) => {
            el.orders.map((e) => {
                e.isChecked = false;
                return e;
            });

            return el;
        });
    };
    removeOrderFromProductionTask = async (order_data) => {
        const res = await this._sendData("/production-task/", "POST", {
            action: order_data.action,
            orders: order_data.orders,
            task: order_data.task
        });
        if (res) {
            return res.length > 0 ? res : [];
        }
    };
    deleteProductionTask = async (id) => {
        const res = await this._sendData(`/production-task/${id}`, "DELETE", {});
        if (res) {
            return res ? res : [];
        }
    };
    autocompleteTask = async (status, id) => {
        return await this._sendData("/orders", "POST", {
            status_auto: status,
            order_id: id
        });
    };

    cacheResetTask = async ({firm, order}) => {
        return await this._sendData(`/orders/filter/?firm=${firm}&cach_clear=on&order=${order}`);
    }

    uploadOne_C_Code = async ({firm, order}) => {
        return await this._sendData(`/orders/filter/?firm=${firm}&1c_sync=start&order=${order}`);
    }

    giveMaterialGoods1CSystem = async (data) => {
        let auth = "U2VydmljZUlGUDpNVFU1TnpVeg=='";
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');

        headers.append('Access-Control-Allow-Origin', 'http://localhost:3000');
        headers.append('Access-Control-Allow-Credentials', 'true');

        headers.append('POST', 'POST', 'OPTIONS');

        // headers.append('Authorization', 'Basic ' + base64.encode(username + ":" + password));
        headers.append('Authorization', 'Basic' + auth);

        // return await  this._sendData('https://1c.abris.ua/DSP/hs/GetDocuments/V2/IneriorOrder', "POST", data)
        // const res = await fetch(`https://1c.abris.ua/DSP/hs/GetDocuments/V2/IneriorOrder`, {
        //     method: "POST",
        //     mode: "cors",
        //     // cache: "no-cache",
        //     credentials: "include",
        //     headers: headers,
        //     redirect: "follow",
        //     referrerPolicy: "no-referrer",
        //     body: JSON.stringify(data),
        // });
        // return await res.json();

        return {
            "data": [
                {
                    "id_goods": "18579",
                    "plan": 5,
                    "Remaining_to_ship": 5,
                    "Shipped": 0,
                    "Reserve": 5,
                    "Ordered": 0,
                    "Free_Balance": 75,
                    "On_credit": "0",
                    "delivery": [
                        {
                            "date_of_delivery": "",
                            "count_of_delivery": 0
                        }
                    ]
                },
                {
                    "id_goods": "18596",
                    "plan": 12,
                    "Remaining_to_ship": 12,
                    "Shipped": 0,
                    "Reserve": 12,
                    "Ordered": 0,
                    "Free_Balance": 19,
                    "On_credit": "0",
                    "delivery": [
                        {
                            "date_of_delivery": "",
                            "count_of_delivery": 0
                        }
                    ]
                },
                {
                    "id_goods": "18441",
                    "plan": 11,
                    "Remaining_to_ship": 11,
                    "Shipped": 0,
                    "Reserve": 11,
                    "Ordered": 0,
                    "Free_Balance": 158,
                    "On_credit": "0",
                    "delivery": [
                        {
                            "date_of_delivery": "",
                            "count_of_delivery": 0
                        }
                    ]
                },
                {
                    "id_goods": "14512",
                    "plan": 17.818,
                    "Remaining_to_ship": 17.818,
                    "Shipped": 0,
                    "Reserve": 0.43,
                    "Ordered": 0,
                    "Free_Balance": -46.368,
                    "On_credit": "0",
                    "delivery": [
                        {
                            "date_of_delivery": "",
                            "count_of_delivery": 0
                        },
                        {
                            "date_of_delivery": "",
                            "count_of_delivery": 0
                        }
                    ]
                },
                {
                    "id_goods": "18508",
                    "plan": 29,
                    "Remaining_to_ship": 29,
                    "Shipped": 0,
                    "Reserve": 29,
                    "Ordered": 0,
                    "Free_Balance": 0,
                    "On_credit": "0",
                    "delivery": [
                        {
                            "date_of_delivery": "",
                            "count_of_delivery": 0
                        }
                    ]
                },
                {
                    "id_goods": "18446",
                    "plan": 2,
                    "Remaining_to_ship": 2,
                    "Shipped": 0,
                    "Reserve": 2,
                    "Ordered": 0,
                    "Free_Balance": 272,
                    "On_credit": "0",
                    "delivery": [
                        {
                            "date_of_delivery": "",
                            "count_of_delivery": 0
                        }
                    ]
                },
                {
                    "id_goods": "18489",
                    "plan": 5,
                    "Remaining_to_ship": 5,
                    "Shipped": 0,
                    "Reserve": 5,
                    "Ordered": 0,
                    "Free_Balance": 34,
                    "On_credit": "0",
                    "delivery": [
                        {
                            "date_of_delivery": "",
                            "count_of_delivery": 0
                        }
                    ]
                }
            ],
            "mutual_settlements": {
                "plan": 7509.12,
                "paid": 7509.12
            },
            "status": "ok"
        }
    }


    changeContragent = async (data) => {
        return await this._sendData(`/orders`, "POST", data);
    }

    getMainSettings = async () => {
        return await this._sendData(`/settings/${localStorage.getItem("i_furn_user_firm")}`)
    }

    getLeftoversOrderAddForm = async (payload) => {
        return await this._sendData('/clients', "POST", payload)
    }
    sendWordTranslation = async (words) => {
        return await this._sendData('/translate/', "POST", {need_translate: words})
    }
    changeLanguage = async (lang) => {
        return await this._sendData('/translate/', "PUT", {lang: lang})
    }
    sendPullAllDT = async (words) => {
        return await this._sendData('/translate/', 'PUT', {put_all: 1, put_all_data: words})
    }
    _deleteFilesListManager = async (url) => {
        return await fetch(url, {
            method: "GET",
            cache: "no-cache",
            credentials: "include",
            referrerPolicy: 'unsafe-url',
            headers: {
                'X-Api-Key': this._apiKey,
                'Language': localStorage.getItem('lang') || 'ua',

            },
        })
            .then(data => {
                return data.json();
            })
            .catch(data => {
                return data.json()
            })
    }
    _getFilesListManager = async (idProject) => {
        return await this._sendData(`/files/${idProject}/`, "GET")
    }
    _importProject = async (files, url = 'import', data = null) => {

        if (!files) return false;

        const res = await this._sendFileData(
            `/${url}`,
            files,
            data,
        );
        if (res) {
            return res;
        } else {
            return false;
        }
    }

    _orderChangePaymountStatus = async ({code, order_id}) => {
        return await this._sendData('/orders', "POST", {code, order_id, order_change_payment_status: "change"})
    }
    _resetRegisterPassword = async (client_id, data) => {
        return await this._sendData(
            `/registration/` + client_id,
            'POST',
            {
                action: "forgot_password",
                login: data.login
            }
        )
    }
    _deleteOrder = async ({v, code}) => {
        return await this._sendData(`/deleteorders/o/${code}`)
    }
    _archiveProjectGetAll = async () => await this._sendData('/archiveproject/')
    _archiveProjectDownload = async (code) => await this._sendData('/archiveproject/download/' + code)
    _archiveProjectFilter = async (data) => {
        return await this._sendData(`/archiveproject`, "POST", data)
    }
    _getAllProjectOrderMaterials = async () => {
        return await this._sendData('/production-task/goods')
    }
    _getByIdProjectOrderMaterial = async ({id}) => {
        return await this._sendData('/production-task/orders/' + id)
    }
    _updateCommentOfOrder = async ({orderId, comment}) => {
        return await this._sendData(`/orders_comment/${orderId}`, "PUT", {comment: comment})
    }
    _getPartsSmallPreviews = async (order) => {

        if (!order) return false;
        // const res = await fetch(`${this.__baseUrlNG}images/`,
        const res = await fetch(`${this._baseUrl}/images/`,
            {
                method: 'POST',
                mode: "cors",
                cache: "no-cache",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json",
                    Firm: localStorage.getItem("i_furn_user_firm"),
                    "Authorization": "Basic U2VydmljZUlGUDpNVFU1TnpVeg=='",
                    'X-Api-Key': this._apiKey,
                    'Language': localStorage.getItem('lang') || 'ua',
                },
                redirect: "follow",
                referrerPolicy: 'unsafe-url',
                body: JSON.stringify({
                    json: {
                        order: order
                    },
                    view: {
                        path: 4
                    },
                    action: 'svg_cart'
                })
            });

        const body = await res.json();
        if (body && body.image) {
            return body.image;
        } else {
            return false;
        }
    }
    _fetchIfurnLables = async (orderId, part_id) => {
        const res = await fetch(`${this._baseUrl}/labels/${orderId}/${part_id}`, {
            method: "GET",
            mode: "cors",
            cache: "no-cache",
            credentials: "include",
            redirect: "follow",
            referrerPolicy: "no-referrer"
        });
        if (res.ok) {
            return await res.text();
        } else {
            return false;
        }
    };

    _fetchGiblabLabels = async (order) => {
        return await this._sendData('/labels/', "POST", {order: order})

    };
    _getNews=async (page=1)=>{
        return this._sendData(`/news?page=${page}`)
    }



}

export default ApiService;
