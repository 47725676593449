import {dispatcherErrorThunk} from "../common_thunk";
import {toggleLoaderStatusAC} from "../../reducers/MainReducer";
import {wholesaleGoodsPartnerSetupDataAC, wholesaleGoodsStockRetailOfFirmDataAC} from "../../actions/WholesaleActions";
import {WholesaleGoodsStockRetailAPI} from "../../../api/Wholesale/WholesaleGoodsStockRetailAPI";
import {WholesaleGoodsPartnerSetupAPI} from "../../../api/Wholesale/WholesaleGoodsPartnerSetupAPI";


async function dispatchedGoodsPartnerSetup(dispatch) {
    const response = await WholesaleGoodsPartnerSetupAPI.getGoodsPartnerSetUp();
    if (response.hasOwnProperty('error') && response?.error) {
        dispatch(dispatcherErrorThunk(response));
        return
    }
    dispatch(wholesaleGoodsPartnerSetupDataAC(response?.data))
}

export const getWSGoodsPartnerSetupThunk = () => async (dispatch) => {
    try {
        dispatch(toggleLoaderStatusAC(true));
        await dispatchedGoodsPartnerSetup(dispatch)

        dispatch(toggleLoaderStatusAC(false));

    } catch (e) {
        dispatch(dispatcherErrorThunk(e))
    }
}

export const setWSGoodsPartnerSetupThunk = (data) => async (dispatch) => {
    try {
        dispatch(toggleLoaderStatusAC(true));
        const response = await WholesaleGoodsPartnerSetupAPI.setGoodsPartnerSetUp(data);
        if (response.hasOwnProperty('error') && response?.error) {
            dispatch(dispatcherErrorThunk(response));
            return
        }

        await dispatchedGoodsPartnerSetup(dispatch)

        dispatch(toggleLoaderStatusAC(false));
    } catch (e) {
        dispatch(dispatcherErrorThunk(e))
    }
}
export const deleteWSGoodsPartnerSetupThunk = (data) => async (dispatch) => {
    try {
        dispatch(toggleLoaderStatusAC(true));
        const response = await WholesaleGoodsPartnerSetupAPI.deleteGoodsPartnerSetUp(data);
        if (response.hasOwnProperty('error') && response?.error) {
            dispatch(dispatcherErrorThunk(response));
            return
        }

        await dispatchedGoodsPartnerSetup(dispatch)

        dispatch(toggleLoaderStatusAC(false));
    } catch (e) {
        dispatch(dispatcherErrorThunk(e))
    }
}
