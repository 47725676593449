import React from 'react';
import {WHOLESALE_MATERIAL_TYPE_STATUS} from "../../../constans/whole_sale_constants";
import itemGoods from "./ItemGoods";


const ItemGoodsInputCheckbox = ({type_operation, handlerChange, status, disabled}) => {
    if (!type_operation) return null;
    let checked = !!Number(status[type_operation]);

    return (
        // <div className="form-group form-check">
            <input className={"mr-2 " + (disabled ? " " : "cursor-pointer")} style={{transform: "scale(1.2)"}} disabled={disabled} checked={checked} onChange={handlerChange}
                   type={"checkbox"}/>
        // </div>
    );
};

export default ItemGoodsInputCheckbox;