import React, {useState} from 'react';
import NoImage from "../../../Icons/NoImage";

const ItemGoodsStockRetail = ({goods, is_product_on_sale = null, getT, handlerOpenModalAddProduct, children, handlerAddGoodsRetailPriceModal}) => {
    const [isOpen, setToggleOpen] = useState(false);
    // const {  ref, isVisible:isOpen, setVisible:setToggleOpen} = useClickOutside();
    const handlerToggleOpenMore = () => {
        setToggleOpen(!isOpen);
    }
    let picture = goods?.pic?.length ? goods.pic[0] : null;
    let is_marked = !!Number(goods?.marked)
    return (
        <div className={`goods-item-wrap`}>
            <div className="goods-info cursor-pointer">
                {picture ? <img src={picture} alt=""/> : <NoImage/>}

                <div className="description">

                    <span
                        className={`title ${is_marked ? " marked_include_text " : ''}`}> [{goods.id}] {goods?.firm_name ? goods?.firm_name : goods?.name}</span>
                    <br/>
                    {goods?.description &&
                        <span className={` ${is_marked ? " marked_include_text " : ''}`}>{goods.description}</span>}
                </div>
                <div className="d-flex align-items-start justify-content-end" style={{gap: "10px"}}>
                    {is_product_on_sale ? <div className={"d-flex align-items-center justify-content-end"} style={{gap: "10px"}}>
                            <button className={"btn btn-outline-success btn-sm"}
                                    style={{width: "max-content", maxWidth: "100%"}}
                                    onClick={handlerAddGoodsRetailPriceModal}>{getT("Добавить цену для партнёра")}</button>
                            <button onClick={handlerToggleOpenMore} className={"btn "}>
                                <i className={"fas fa-angle-" + (isOpen ? "up" : "down")}></i>
                            </button>
                        </div>
                        : <button className={"btn btn-success btn-sm ml-auto"}
                                  style={{width: "max-content", maxWidth: "100%"}}
                                  onClick={handlerOpenModalAddProduct}>{getT("Добавить товар в продажу")}</button>
                    }
                </div>
            </div>
            {isOpen && <>
                {children}
            </>}
        </div>
    );
};

export default ItemGoodsStockRetail;