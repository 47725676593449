import React, {useMemo} from 'react';
import Modal from "../../../../components/Modals/MyModal/Modal";
import {useDispatch, useSelector} from "react-redux";
import {getWSGoodsPartnerSetup, getWSGoodsPartnerSetupModal} from "../../../../store/selectors/wholesalary_selector";
import {wholesaleGoodsPartnerSetupModalToggleAC} from "../../../../store/actions/WholesaleActions";
import GoodsPartnerSetupForm from "./GoodsPartnerSetupForm";
import {LS_FIRM} from "../../../../utils/constants";
import {
    deleteWSGoodsPartnerSetupThunk,
    setWSGoodsPartnerSetupThunk
} from "../../../../store/thunks/wholesale/goods_partner_setup_thunk";

const WSGoodsPartnerSetupModal = ({getT}) => {
    const dispatch = useDispatch();
    const goods_partner_setup = useSelector(getWSGoodsPartnerSetup)
    const modal_state = useSelector(getWSGoodsPartnerSetupModal);


    const handlerClose = () => {
        dispatch(wholesaleGoodsPartnerSetupModalToggleAC({isOpen: false, goods: null, firm_main: null}))
    }
    const goods_setup = useMemo(() => {
        if (!modal_state?.isOpen || !modal_state?.goods || !goods_partner_setup) return null;
        return goods_partner_setup?.find(e => {
            let current_goods_id = Number(modal_state?.goods?.goods_id);
            let firm_main = Number(modal_state?.firm_main) === Number(e?.firm_main);
            let firm_partner = Number(localStorage.getItem(LS_FIRM)) === Number(e?.firm_partner);
            return (Number(e?.goods) === current_goods_id) && (firm_main) && (firm_partner)
        }) ?? null
    }, [modal_state, goods_partner_setup])

    if (!modal_state.isOpen) return null
    const handlerSend = (data) => {
        let goods = modal_state?.goods?.goods_id;
        let firm_main = modal_state?.firm_main;
        Object.keys(data).forEach((key) => {
            data[key] = Number(data[key])
        })
        dispatch(setWSGoodsPartnerSetupThunk([{...data, goods: goods, firm_main: firm_main}]))
    }
    const handlerDelete = () => {
        let id = goods_setup?.ws_goods_partner_setup_id ?? null
        dispatch(deleteWSGoodsPartnerSetupThunk([{id: id}]))
    }

    return (
        <Modal open={modal_state?.isOpen} onClose={handlerClose} title={<div style={{fontSize:"16px", fontWeight:"600"}}>{modal_state?.goods?.name} </div>}>
            {goods_setup && <div className={"text-center"}>
                <ul className="list-group list-group-flush text-left">
                    {!!Number(goods_setup?.price_by_retail) &&
                        <li className="list-group-item">
                            <strong>{getT("Тип продажи")}: </strong> {getT("Продажа по розничной цене Оптовика")}</li>}
                    {!!Number(goods_setup?.price_0) &&
                        <li className="list-group-item">
                            <strong>{getT("Тип продажи")}: </strong> {getT("Продажа по 0.01 товара")}</li>}
                    {!!Number(goods_setup?.price_by_income) && <>
                        <li className="list-group-item">
                            <strong>{getT("Тип продажи")}: </strong> {getT("Продажа с наценкой от входящей цены")}</li>
                        <li className={"list-group-item"}>
                            <strong>{getT("Процент от продажи")}: </strong> {goods_setup?.price_by_income_percentage}
                        </li>
                    </>}
                    <li className="list-group-item">
                        <strong>{getT("Количетсво дней к доставке")}: </strong> {goods_setup?.days_delivery_add}</li>
                    <li className="list-group-item">
                        <strong>{getT("Использовать ограничение по минимальному количеству при заказе")}: </strong>{!!Number(goods_setup?.minimal_quantity_restriction) ? getT("да") : getT("нет")}</li>
                </ul>
                <button className={"btn btn-danger mt-3"}
                        onClick={handlerDelete}>{getT("Удалить настройку товара")}</button>
            </div>}
            {!goods_setup && <GoodsPartnerSetupForm goods_setup={goods_setup} getT={getT} handlerSend={handlerSend}/>}
        </Modal>
    );
};

export default WSGoodsPartnerSetupModal;