import React from 'react';
import ItemGoodsStockRetail from "./ItemGoodsStockRetail";
import {useDispatch} from "react-redux";
import {
    wholesaleGoodsRetailPriceAddModalAC,
    wholesaleGoodsStockRetailOfFirmAddModalAC
} from "../../../store/actions/WholesaleActions";
import {deleteWSGoodsStockRetailThunk} from "../../../store/thunks/wholesale/goods_stock_retail_of_firm_thunk";
import ItemGoodsStockRetailData from "./ItemGoodsStockRetailData";
import ItemGoodsRetailPriceTable from "./ItemGoodsRetailPriceTable";
import {deleteWSGoodsRetailPriceThunk} from "../../../store/thunks/wholesale/goods_retail_price_thunk";
import {LS_FIRM} from "../../../utils/constants";

const GoodsStockList = ({getT, goods, goods_stock_retail_dictionary, goods_retail_price_direction}) => {
    const dispatch = useDispatch();
    const handlerOpenModalAddProduct = (goods) => (e) => {
        dispatch(wholesaleGoodsStockRetailOfFirmAddModalAC({isOpen: true, goods: goods}))
    }
    const handlerDeleteGoodsStockRetail = (goods, goods_retail_price) => (e) => {
        dispatch(deleteWSGoodsStockRetailThunk({
            api_delete_stock: [{id: goods?.ws_goods_stock_source_retail_id}],
            goods_retail_price: goods_retail_price
        }))
    }
    const handlerDeleteGoodsRetailPrice = (id) => (e) => {
        dispatch(deleteWSGoodsRetailPriceThunk({
            api_data_delete: [{id: id}],
            params: {firm_main: localStorage.getItem(LS_FIRM)}
        }))
        // dispatch(deleteWSGoodsStockRetailThunk([{id: id}]))
    }
    const handlerAddGoodsRetailPriceModal = (goods_stock_retail, goods_retail_price) => e => {
        console.log('click')
        dispatch(wholesaleGoodsRetailPriceAddModalAC({
            isOpen: true,
            goods: goods_stock_retail,
            goods_retail_price: goods_retail_price
        }))
    }
    return (
        <div className={'material-selected-goods bg-white'} style={{}}>
            {goods?.map(item => {
                let goods_stock_retail = goods_stock_retail_dictionary?.[item?.id] ?? null;
                let goods_retail_price = goods_retail_price_direction?.[item?.id] ?? null;
                return (
                    <ItemGoodsStockRetail goods={item} getT={getT} key={`goods_item_${item?.id}`}
                                          is_product_on_sale={!!goods_stock_retail}
                                          goods_retail={goods_stock_retail}
                                          handlerOpenModalAddProduct={handlerOpenModalAddProduct(item)}
                                          handlerAddGoodsRetailPriceModal={handlerAddGoodsRetailPriceModal(goods_stock_retail, goods_retail_price)}
                        // handlerDeleteGoodsStockRetail={handlerDeleteGoodsStockRetail(goods_stock_retail)}
                    >

                        {goods_stock_retail && <>
                            <ItemGoodsStockRetailData key={`goods_stock_retail_${item?.id}`} goods_retail={goods_stock_retail} getT={getT}
                                                      handlerDeleteGoodsStockRetail={handlerDeleteGoodsStockRetail(goods_stock_retail, goods_retail_price)}/>
                        </>}
                        {
                            (goods_stock_retail) &&
                            <ItemGoodsRetailPriceTable key={`goods_item_${item?.id}`} getT={getT} goods_retail_price={goods_retail_price}
                                                       handlerDeleteGoodsRetailPrice={handlerDeleteGoodsRetailPrice}/>
                        }


                    </ItemGoodsStockRetail>

                )
            })}
        </div>
    );
};

export default GoodsStockList;