import React, {useMemo} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {getCurrency} from "../../../../store/selectors/main_selector";
import Modal from "../../../../components/Modals/MyModal/Modal";
import {getWSConnectionClient, getWSGoodsRetailPriceAddModal} from "../../../../store/selectors/wholesalary_selector";
import {wholesaleGoodsRetailPriceAddModalAC} from "../../../../store/actions/WholesaleActions";
import {useForm} from "react-hook-form";
import {LS_FIRM, LS_LANG} from "../../../../utils/constants";
import {setWSGoodsRetailPriceThunk} from "../../../../store/thunks/wholesale/goods_retail_price_thunk";
import useGetMaterialById from "../../../../hook/useGetMaterialById";
import Loader from "../../../../components/Loader/Loader";

/**
 * WSGoodsRetailAddModal. Модальное окно для установить цен для конкретного клиента.
 *
 * @returns  {jsx}  -
 */
const WSGoodsRetailAddModal = ({getT}) => {
    const dispatch = useDispatch();
    const currency = useSelector(getCurrency);
    const connection_clients = useSelector(getWSConnectionClient);
    const goods_retail_price_add_modal = useSelector(getWSGoodsRetailPriceAddModal);
    const {data: material_data, isPending, error} = useGetMaterialById({
        goods_id: goods_retail_price_add_modal?.goods?.goods,
        firm: localStorage.getItem(LS_FIRM)
    })

    const connection_clients_actual = useMemo(() => {
        if (!goods_retail_price_add_modal?.isOpen) return null
        let current_firm = Number(localStorage.getItem(LS_FIRM));
        if (!connection_clients) return null;
        let goods_retail_price = goods_retail_price_add_modal?.goods_retail_price;
        console.log('goods_retail_price', goods_retail_price)
        let data = connection_clients?.reduce((acc, item) => {
            let firm_name = Number(item?.main_firm);
            if (firm_name !== current_firm) return acc;

            let firm_partner = Number(item?.partner_firm);
            let firm_partner_has_retail_price = goods_retail_price ? goods_retail_price?.some(e => Number(firm_partner) === Number(e?.firm_partner)) : false;
            let key = `key_${firm_partner}`;
            let value_of_key = {firm_partner: item?.partner_firm, name: item?.partner_firm_name};
            let is_active = !!Number(item?.active) && item?.client;

            if ((acc?.hasOwnProperty(key) && !is_active) || firm_partner_has_retail_price) {
                return {
                    ...acc, [key]: null
                }
            }
            return {
                ...acc, [key]: value_of_key
            }

            return acc
        }, {})
        return Object.values(data)?.filter(e => !!e)
    }, [connection_clients, goods_retail_price_add_modal]);

    if (!goods_retail_price_add_modal?.isOpen) return null;
    const handlerClose = (e) => {
        dispatch(wholesaleGoodsRetailPriceAddModalAC({isOpen: false, goods: null}))
    }
    const handlerSend = (data) => {
        let firm_main = Number(localStorage.getItem(LS_FIRM));
        let api_data = [{
            ...data,
            firm_main: firm_main,
            goods: Number(goods_retail_price_add_modal?.goods?.goods),

        }]
        let params = {firm_main: firm_main};
        dispatch(setWSGoodsRetailPriceThunk({api_data_set: api_data, params: params}))
    }
    return (
        <>
        <Modal open={goods_retail_price_add_modal?.isOpen && !isPending} onClose={handlerClose}
               title={<div style={{
                   fontSize: "16px",
                   fontWeight: "600"
               }}> [{goods_retail_price_add_modal?.goods?.goods}] {goods_retail_price_add_modal?.goods?.goods_name_translated}</div>}>
            <GoodsRetailPriceAddForm getT={getT} currency={currency} handlerSend={handlerSend}
                                     connection_firms={connection_clients_actual} recommended_retail_price={material_data?.price}/>
        </Modal>
            {isPending && <Loader/>}
        </>
    );
};

const GoodsRetailPriceAddForm = ({getT, currency, handlerSend, connection_firms, recommended_retail_price}) => {
    const {register, handleSubmit, formState: {errors}} = useForm({
        defaultValues: {
            firm_partner: '',
            currency: 2,
            recommended_retail_price: recommended_retail_price ?? null, // 34.6
            income_price: null, // 1
            recommended_profit_percentage: 1,
            default_delivery_days: 1,
            minimal_quantity_for_order: 1,
        }
    });

    const onSubmit = (data) => {
        handlerSend(data)
    };

    return (
        <div className="">
            <form onSubmit={handleSubmit(onSubmit)}>

                {/* Остаток розничный */}
                <div className="form-group row align-items-end" style={{rowGap: "15px"}}>
                    {/* Цена продажи */}
                    <div className="col-12 col-md-6">
                        <label htmlFor="recommended_retail_price">{getT("Цена продажи")}</label>
                        <input
                            id="income_price"
                            type="number"
                            step={0.01}
                            min={1}
                            className="form-control"
                            required={true}

                            {...register('income_price', {
                                setValueAs: (v) => Number(v)
                            })}
                        />
                        {errors.income_price &&
                            <span className="text-danger">{errors.income_price.message}</span>}
                    </div>

                    {/* Рекомендуемая розничная цена*/}
                    <div className="col-12 col-md-6">
                        <label htmlFor="retail_stock">{getT("Рекомендуемая розничная цена")}</label>
                        <input
                            id="recommended_retail_price"
                            type="number"
                            step="0.01"
                            className="form-control"
                            required={true}
                            min={1}
                            {...register('recommended_retail_price', {
                                setValueAs: (v) => Number(v)
                            })}
                        />
                        {errors.recommended_retail_price &&
                            <span className="text-danger">{errors.recommended_retail_price.message}</span>}
                    </div>
                    {/* Рекомендуемый процент прибыли */}
                    <div className="col-12 col-md-6">
                        <label htmlFor="whole_sale_stock">{getT("Рекомендуемый процент прибыли")}</label>
                        <input
                            id="recommended_profit_percentage"
                            type="number"
                            required={true}
                            min={1}
                            step={0.01}
                            className="form-control"
                            {...register('recommended_profit_percentage', {
                                setValueAs: (v) => Number(v)
                            })}
                        />
                        {errors.recommended_profit_percentage &&
                            <span className="text-danger">{errors.recommended_profit_percentage.message}</span>}
                    </div>

                    {/* Валюта */}
                    <div className="col-12 col-md-6">
                        <label htmlFor="currency">{getT("Валюта")}</label>
                        <select id="currency" className="form-control"
                                required={true}
                                {...register('currency', {
                                    setValueAs: (v) => Number(v)
                                })}>
                            <option value={''}>---------</option>

                            {currency?.map(e => {
                                return <option
                                    value={e?.currency_id}>{e?.name?.[localStorage.getItem(LS_LANG)]}</option>
                            })}
                        </select>
                        {errors.currency && <span className="text-danger">{errors.currency.message}</span>}
                    </div>

                    {/*/!* Минимальное количество *!/*/}
                    <div className="col-12 col-md-6">
                        <label htmlFor="minimal_quantity">{getT("Минимальное количеств при заказе")}</label>
                        <input
                            id="minimal_quantity_for_order"
                            type="number"
                            required={true}
                            step={1}
                            min={1}
                            className="form-control"
                            {...register('minimal_quantity_for_order', {
                                setValueAs: (v) => Number(v)
                            })}
                        />
                        {errors.default_delivery_days &&
                            <span className="text-danger">{errors.minimal_quantity_for_order.message}</span>}
                    </div>
                    {/* Дней на доставку */}
                    <div className="col-12 col-md-6">
                        <label htmlFor="default_delivery_days">{getT("Срок доставки по умолчанию")}</label>
                        <input
                            id="default_delivery_days"
                            type="number"
                            required={true}
                            step={1}
                            min={1}
                            className="form-control"
                            {...register('default_delivery_days', {
                                setValueAs: (v) => Number(v)
                            })}
                        />
                        {errors.default_delivery_days &&
                            <span className="text-danger">{errors.default_delivery_days.message}</span>}
                    </div>

                    <div className="col-12 col-md-6">
                        <label htmlFor="currency">{getT("Партнёр")}</label>
                        <select id="firm_partner" className="form-control"
                                required={true}
                                {...register('firm_partner', {
                                    setValueAs: (v) => Number(v)
                                })}>
                            <option value={''}>---------</option>
                            {connection_firms?.map(e => {
                                return <option value={e?.firm_partner}>{e?.name}</option>
                            })}
                        </select>
                        {errors.firm_partner && <span className="text-danger">{errors.firm_partner.message}</span>}
                    </div>

                </div>


                {/* Кнопка отправки */
                }
                <div className="d-flex mt-5">
                    <button type="submit" className="btn btn-primary mx-auto">{getT("Отправить")}</button>
                </div>
            </form>
        </div>
    )
        ;
}

export default WSGoodsRetailAddModal;