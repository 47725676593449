import {dispatcherErrorThunk} from "../common_thunk";
import {toggleLoaderStatusAC} from "../../reducers/MainReducer";
import {
    wholesaleGoodsStockRetailOfFirmAddModalAC,
    wholesaleGoodsStockRetailOfFirmDataAC
} from "../../actions/WholesaleActions";
import {WholesaleGoodsStockRetailAPI} from "../../../api/Wholesale/WholesaleGoodsStockRetailAPI";
import {dispatchedMaterialSearchFiltered, filteredMaterialSearchThunk} from "../material_search/material_search_thunk";
import {WholesaleGoodsRetailPriceAPI} from "../../../api/Wholesale/WholesaleGoodsRetailPriceAPI";
import {dispatchedGoodsRetailPrice} from "./goods_retail_price_thunk";
import {LS_FIRM} from "../../../utils/constants";

async function dispatchedGetStockRetail(api_data = null, dispatch) {
    const response = await WholesaleGoodsStockRetailAPI.getStockRetail(api_data);
    if (response.hasOwnProperty('error') && response?.error) {
        dispatch(dispatcherErrorThunk(response));
        dispatch(wholesaleGoodsStockRetailOfFirmDataAC(null))
        return null
    }
    dispatch(wholesaleGoodsStockRetailOfFirmDataAC(response?.data))

    return response?.data
}

export const getWSGoodsStockRetailOfFirmThunk = (data = null) => async (dispatch) => {
    try {
        dispatch(toggleLoaderStatusAC(true));
        await dispatchedGetStockRetail(data, dispatch);
        dispatch(toggleLoaderStatusAC(false));

    } catch (e) {
        dispatch(dispatcherErrorThunk(e))
    }
}

export const filteredWSGoodsStockRetailOfFirmAndMaterialSearchThunk = (data) => async (dispatch) => {
    try {
        dispatch(toggleLoaderStatusAC(true));
        const response = await dispatchedMaterialSearchFiltered(data, dispatch)
        let data_api_for_stocks = response?.goods?.map(e => Number(e?.id));
        await dispatchedGetStockRetail({only: data_api_for_stocks}, dispatch);
        dispatch(toggleLoaderStatusAC(false));

    } catch (e) {
        dispatch(dispatcherErrorThunk(e))
    }
}
export const addWSGoodsStockRetailThunk = (data) => async (dispatch, getState) => {
    try {
        const material_goods = getState().material_search?.goods ?? [];
        dispatch(toggleLoaderStatusAC(true));
        const response = await WholesaleGoodsStockRetailAPI.setStockRetail(data);
        if (response.hasOwnProperty('error') && response?.error) {
            dispatch(dispatcherErrorThunk(response));
            return null
        }
        let data_api_for_stocks = material_goods?.map(e => Number(e?.id));
        await dispatchedGetStockRetail({only: data_api_for_stocks}, dispatch);
        dispatch(wholesaleGoodsStockRetailOfFirmAddModalAC({isOpen: false, goods: null}))
        dispatch(toggleLoaderStatusAC(false));

    } catch (e) {
        dispatch(dispatcherErrorThunk(e));
    }
}


/**
 * deleteWSGoodsStockRetailThunk. Удаление настройки товара продажи в опт, так же удалить все настройки цен для партнеров по этому товару. получить обновленые данные
 * @param {array} api_delete_stock - id настройки овара продажи в опт
 * @param {array | null} goods_retail_price - настройки цен для партнеров
 * @returns  {undefined}  -
 */
export const deleteWSGoodsStockRetailThunk = ({api_delete_stock, goods_retail_price}) => async (dispatch, getState) => {
    try {

        const material_goods = getState().material_search?.goods ?? [];
        dispatch(toggleLoaderStatusAC(true));
        const response = await WholesaleGoodsStockRetailAPI.deleteStockRetail(api_delete_stock);
        if (response.hasOwnProperty('error') && response?.error) {
            dispatch(dispatcherErrorThunk(response));
            return null
        }
        if (goods_retail_price) {
            let data_api = goods_retail_price?.map(e => ({
                id: Number(e?.ws_goods_retail_price_id)
            }))
            const response_retail_price = await WholesaleGoodsRetailPriceAPI.deleteRetailPrice(data_api);
            if (response_retail_price.hasOwnProperty('error') && response_retail_price?.error) {
                dispatch(dispatcherErrorThunk(response_retail_price));
                return null
            }
            await dispatchedGoodsRetailPrice({firm_main: localStorage.getItem(LS_FIRM)}, dispatch)
        }
        let data_api_for_stocks = material_goods?.map(e => Number(e?.id));
        await dispatchedGetStockRetail({only: data_api_for_stocks}, dispatch);
        dispatch(toggleLoaderStatusAC(false));

    } catch (e) {
        dispatch(dispatcherErrorThunk(e));
    }
}