import {dispatcherErrorThunk} from "../common_thunk";
import {toggleLoaderStatusAC} from "../../reducers/MainReducer";
import {wholesaleGoodsRetailPriceAddModalAC, wholesaleGoodsRetailPriceSetAC} from "../../actions/WholesaleActions";
import {WholesaleGoodsRetailPriceAPI} from "../../../api/Wholesale/WholesaleGoodsRetailPriceAPI";


export const dispatchedGoodsRetailPrice = async (params, dispatch) => {
    const response = await WholesaleGoodsRetailPriceAPI.getRetailPrice(params);
    if (response.hasOwnProperty('error') && response?.error) {
        dispatch(dispatcherErrorThunk(response));
        return
    }
    dispatch(wholesaleGoodsRetailPriceSetAC(response?.data))
}

export const getWSGoodsRetailPriceByParamsThunk = (params) => async (dispatch) => {
    try {
        dispatch(toggleLoaderStatusAC(true));
        await dispatchedGoodsRetailPrice(params, dispatch)
        dispatch(toggleLoaderStatusAC(false));

    } catch (e) {
        dispatch(dispatcherErrorThunk(e))
    }
}

export const setWSGoodsRetailPriceThunk = ({api_data_set, params}) => async (dispatch) => {
    try {
        dispatch(toggleLoaderStatusAC(true));
        const response = await WholesaleGoodsRetailPriceAPI.setRetailPrice(api_data_set);
        if (response.hasOwnProperty('error') && response?.error) {
            dispatch(dispatcherErrorThunk(response));
            return
        }
        dispatch(wholesaleGoodsRetailPriceAddModalAC({isOpen: false, goods: null}))
        console.log('get goods')
        await dispatchedGoodsRetailPrice(params, dispatch)

        dispatch(toggleLoaderStatusAC(false));
    } catch (e) {
        dispatch(dispatcherErrorThunk(e))
    }
}
export const deleteWSGoodsRetailPriceThunk = ({api_data_delete, params}) => async (dispatch) => {
    try {
        dispatch(toggleLoaderStatusAC(true));
        const response = await WholesaleGoodsRetailPriceAPI.deleteRetailPrice(api_data_delete);
        if (response.hasOwnProperty('error') && response?.error) {
            dispatch(dispatcherErrorThunk(response));
            return
        }

        await dispatchedGoodsRetailPrice(params, dispatch)

        dispatch(toggleLoaderStatusAC(false));
    } catch (e) {
        dispatch(dispatcherErrorThunk(e))
    }
}
