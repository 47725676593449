import {dispatcherErrorThunk} from "../common_thunk";
import {WholesaleAdditionalAPI} from "../../../api/Wholesale/WholesaleAdditionalAPI";
import {toggleLoaderStatusAC} from "../../reducers/MainReducer";
import {wholesaleSetPartnerFirmListAC} from "../../actions/WholesaleActions";
import {createFormData} from "../../../utils/helpers";

export const getWSPartnerFirmListThunk = ()=> async (dispatch)=>{
    try{
        dispatch(toggleLoaderStatusAC(true));
        const response = await WholesaleAdditionalAPI.getFirmsList();
        if(response.hasOwnProperty('error') && response?.error){
            dispatch(dispatcherErrorThunk(response));
            return
        }
        dispatch(wholesaleSetPartnerFirmListAC(response?.data))
        dispatch(toggleLoaderStatusAC(false));

    }
    catch (e) {
        dispatch(dispatcherErrorThunk(e))
    }
}


