export const getWSConnectionClient = state=> state?.wholesale?.connection_clients;
export const getWSPartnerFirmList = state=> state?.wholesale?.partner_firm_list;
export const getWSCompanyClientList = state=> state?.wholesale?.company_client_list;

export const getWSConnectionRequestCompanyClientModal = state=> state?.wholesale?.connection_request_company_client_modal;


export const getWSCompanyConnectionRequestPage = (state)=>{
    return {
        connection_clients: getWSConnectionClient(state),
        partner_firm_list: getWSPartnerFirmList(state),
        company_client_list: getWSCompanyClientList(state),

    }
}
export const getWSOperationsWithProductGroupsFirm = state => state?.wholesale?.operations_with_product_groups_firm;

export const getWSGoodsStockRetailOfFirm = state => state?.wholesale?.goods_stock_retail_of_firm;
export const getWSGoodsStockRetailOfFirmAddModal = state => state?.wholesale?.goods_stock_retail_of_firm_add_modal;
export const getWSGoodsPartnerSetup = state => state?.wholesale?.goods_partner_setup;
export const getWSGoodsPartnerSetupModal = state => state?.wholesale?.goods_partner_setup_modal;

export const getWSGoodsRetailPrice = state => state?.wholesale?.goods_retail_price;
export const getWSGoodsRetailPriceReadModal = state => state?.wholesale?.goods_retail_price_read_modal;
export const getWSGoodsRetailPriceAddModal = state => state?.wholesale?.goods_retail_price_add_modal;

