import React from 'react';
import Modal from "../../../Modals/MyModal/Modal";
import {useDispatch, useSelector} from "react-redux";
import {getWSGoodsRetailPriceReadModal} from "../../../../store/selectors/wholesalary_selector";
import {wholesaleGoodsRetailPriceReadModalAC} from "../../../../store/actions/WholesaleActions";

const WsGoodsRetailPriceReadModal = ({getT}) => {
    const dispatch = useDispatch();
    const goods_retail_price_modal = useSelector(getWSGoodsRetailPriceReadModal);
    console.log('goods_retail_price_modal', goods_retail_price_modal)
    if (!goods_retail_price_modal?.isOpen) return null
    const handlerClose = ()=>{
        dispatch(wholesaleGoodsRetailPriceReadModalAC({goods: null, retail_price: null, isOpen: false}))
    }
    return (
        <Modal open={goods_retail_price_modal?.isOpen} onClose={handlerClose}
               title={getT("Настройка товарова оптовиком")}>
            {goods_retail_price_modal?.retail_price && <div className={"text-center"}>
                <ul className="list-group list-group-flush text-left">
                    <li className="list-group-item">
                        <strong>{getT("Товар")}: </strong>
                        [{goods_retail_price_modal?.goods?.goods_id}] {goods_retail_price_modal?.goods?.name}
                    </li>
                    <li className="list-group-item">
                        <strong>{getT("Рекомендуемая розничная цена")}: </strong>
                        {goods_retail_price_modal?.retail_price?.recommended_retail_price}
                    </li>


                    <li className="list-group-item">
                        <strong>{getT("Цена закупки")}: </strong>
                        {goods_retail_price_modal?.retail_price?.income_price}

                    </li>
                    <li className="list-group-item">
                        <strong>{getT("Валюта")}: </strong>
                        {goods_retail_price_modal?.retail_price?.currency_name}

                    </li>
                    <li className={"list-group-item"}>
                        <strong>{getT("Рекомендуемый процент прибыли")}: </strong> {goods_retail_price_modal?.retail_price?.recommended_profit_percentage}
                    </li>

                    <li className="list-group-item">
                        <strong>{getT("Срок доставки по умолчанию")}: </strong>
                        {goods_retail_price_modal?.retail_price?.default_delivery_days}</li>
                    <li className="list-group-item">
                        <strong>{getT("Минимальное количество для заказа")}: </strong>
                        {goods_retail_price_modal?.retail_price?.minimal_quantity_for_order}
                    </li>
                </ul>

            </div>}
        </Modal>
    );
};

export default WsGoodsRetailPriceReadModal;