import React, {Component} from "react";
import {connect} from "react-redux";
import OrdersGroupDetail from "./OrdersGroupDetail";

import {
  changeCalcTypeIntoAC,
  changeOrderDetailValue,
  changePrintDocumentsDataAC,
  fetchingMergeHtmlFiles,
  getCuttingLabelsForPrint,
  getGiblabDataThunk,
  getPDFLoaderSetterThunk,
  setOrderDetailItemDiscount,
  setProductionOrderDetailDataThunk,
  toggleOrderChangeStatusAC,
  toggleOrderDetailEditThunk,
  updateProductionTaskDetailDataThunk
} from "../../store/reducers/MainReducer";

class GroupOrdersDetailContainer extends Component {
  constructor(props) {
    super(props);
    this.id = this.props.uri.match.params.id;
    this.printDocumentsPackage = this.printDocumentsPackage.bind(this);
  }
  componentDidMount() {
    this.props.getProductionTask(this.id);
  }

  printDocumentsPackage(order) {
    let print_array = [];
    this.props.printDocumentsData.order_blank_short &&
      print_array.push("order_blank_short");
    this.props.printDocumentsData.order_blank_short_without &&
      print_array.push("order_blank_short_without");
    this.props.printDocumentsData.cards && print_array.push("cards");
    this.props.printDocumentsData.xnc && print_array.push("xnc");
    this.props.printDocumentsData.material_list &&
      print_array.push("material_list");
    order.print_array = print_array;
    this.props.getPDF("merge", order);
  }
  componentWillUnmount(){
    // this.props.checkAuntificationUser()
  }
  render() {
    return this.props.productionTask ? (
      <OrdersGroupDetail
        productionTask={this.props.productionTask}
        getPDF={this.props.getPDF}
        discountEdit={this.props.discountEdit}
        editDiscount={this.props.editDiscount}
        discounts={this.props.discounts}
        setDiscount={this.props.setDiscount}
        orderID={this.id}
        isEdit={this.props.isEdit}
        editOrder={this.props.editOrder}
        changePrintDocumentsData={this.props.changePrintDocumentsData}
        printDocumentsData={this.props.printDocumentsData}
        printDocumentsPackage={this.printDocumentsPackage}
        changeValue={this.props.changeValue}
        changeCalcType={this.props.changeCalcType}
        changedStatus={this.props.changedStatus}
        updateOrder={this.props.updateOrder}
        status={this.props.orderStatus}
        userRoles={this.props.user.role}
        toggleChangeStatus={this.props.toggleChangeStatus}
        getLabelsPrintHtml={this.props.getLabelsPrintHtml}
        getGiblabDataThunk={this.props.getGiblabDataThunk}
        fetchingMergeHtml={this.props.fetchingMergeHtml}
      />
    ) : (
      ""
    );
  }
}
let mapDispatchToProps = (dispatch) => {
  return {
    getProductionTask: (id) => dispatch(setProductionOrderDetailDataThunk(id)),
    getPDF: (action, order) => dispatch(getPDFLoaderSetterThunk(action, order)),
    // changePrintDocumentsData: (input, value) =>
    //   dispatch(changePrintDocumentsDataAC(input, value)),

    setDiscount: (id, type, price) =>
      dispatch(setOrderDetailItemDiscount(id, type, price)),
    editOrder: (status, order_id, data) =>
      dispatch(toggleOrderDetailEditThunk(status, order_id, data)),
    changeValue: (input, value) =>
      dispatch(changeOrderDetailValue(input, value)),
    changePrintDocumentsData: (input, value) =>
      dispatch(changePrintDocumentsDataAC(input, value)),
    changeCalcType: (calc_type, id) =>
      dispatch(changeCalcTypeIntoAC(calc_type, id)),
    updateOrder: (order_id, data) =>
      dispatch(updateProductionTaskDetailDataThunk(order_id, data)),
    toggleChangeStatus: (id) => dispatch(toggleOrderChangeStatusAC(id)),
    getLabelsPrintHtml : (action) => dispatch(getCuttingLabelsForPrint(action)),
    getGiblabDataThunk: (type, order) => dispatch(getGiblabDataThunk(type, order)),
    fetchingMergeHtml : (actions, order)=> dispatch(fetchingMergeHtmlFiles({actions, order}))

  };
};

let mapStateToProps = (state) => {
  return {
    productionTask: state.main.production_task_detail,
    printDocumentsData: state.main.printDocuments,
    orderStatus: state.main.orderStatus,
    changedStatus: state.main.ordersChangeStatus,
    user: state.auth.user,
    isEdit: state.main.orderDetailEdit,
    discounts: state.main.orderDiscounts
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GroupOrdersDetailContainer);
