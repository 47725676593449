import {SETTINGS_COMPANY_SET_ENTRY_DATA} from "../actions/SettingsCompanyAction";

const initialState = {
    settings_data: null
}


const SettingsCompanyReducer = (state = initialState, action) => {
    switch (action.type) {
        case SETTINGS_COMPANY_SET_ENTRY_DATA:{
            return {
                ...state,
                settings_data: action.payload
            }
        }
        default: {
            return state
        }
    }
}

export default SettingsCompanyReducer;

