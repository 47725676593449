export const WHOLESALE_CONNECTION_CLIENTS = "WHOLESALE/CONNECTION_CLIENTS"
export const WHOLESALE_SET_COMPANY_CLIENT_LIST = "WHOLESALE/SET_COMPANY_CLIENT_LIST"
export const WHOLESALE_SET_PARTNER_FIRM_LIST = "WHOLESALE/SET_PARTNER_FIRM_LIST";
export const WHOLESALE_TOGGLE_CONNECTION_REQUEST_COMPANY_CLIENT_MODAL = "WHOLESALE/TOGGLE_CONNECTION_REQUEST_COMPANY_CLIENT_MODAL";

export const WHOLESALE_INITIAL_VALUES_FOR_CONNECTION_REQUEST_COMPANY_PAGE = "WHOLESALE/INITIAL_VALUES_FOR_CONNECTION_REQUEST_COMPANY_PAGE";

// Transactions with product groups
export const WHOLESALE_OPERATIONS_WITH_PRODUCT_GROUP_FIRM = "WHOLESALE/OPERATIONS_WITH_PRODUCT_GROUP_FIRM";

export const WHOLESALE_GOODS_STOCK_RETAIL_OF_FIRM_DATA = "WHOLESALE_GOODS/STOCK_RETAIL_OF_FIRM_DATA"
export const WHOLESALE_GOODS_STOCK_RETAIL_OF_FIRM_ADD_MODAL = "WHOLESALE_GOODS/STOCK_RETAIL_OF_FIRM_ADD_MODAL"
export const WHOLESALE_GOODS_PARTNER_SETUP_DATA = "WHOLESALE_GOODS/GOODS_PARTNER_SETUP_DATA"
export const WHOLESALE_GOODS_PARTNER_SETUP_MODAL = "WHOLESALE_GOODS/GOODS_PARTNER_SETUP_MODAL"


export const WHOLESALE_GOODS_RETAIL_PRICE_SET = "WHOLESALE_GOODS/RETAIL_PRICE_SET"
export const WHOLESALE_GOODS_RETAIL_PRICE_READ_MODAL = "WHOLESALE_GOODS/RETAIL_PRICE_READ_MODAL"
export const WHOLESALE_GOODS_RETAIL_PRICE_ADD_MODAL = "WHOLESALE_GOODS/RETAIL_PRICE_ADD_MODAL"


/**
 * WholesaleConnectionClientsAC. устанавливка себе партнёров - покупателей
 *
 * @param {null || object} payload-
 * @returns  {object}  -
 */
export const wholesaleConnectionClientsAC = (payload) => {
    return {
        type: WHOLESALE_CONNECTION_CLIENTS,
        payload: payload
    }
}

/**
 * WholesaleConnectionClientsAC. список клиентов фирмы, Что бы выбрать клиента и активизировать заявку
 *
 * @param {null || object} payload-
 * @returns  {object}  -
 */
export const wholesaleSetCompanyClientListAC = (payload) => {
    return {
        type: WHOLESALE_SET_COMPANY_CLIENT_LIST,
        payload: payload
    }
}


/**
 * WholesaleConnectionClientsAC. список всех доступный партнёров - фирм
 *
 * @param {null || object} payload-
 * @returns  {object}  -
 */
export const wholesaleSetPartnerFirmListAC = (payload) => {
    return {
        type: WHOLESALE_SET_PARTNER_FIRM_LIST,
        payload: payload
    }
}
/**
 * WholesaleConnectionClientsAC. модалка для подтверждения партнера
 *
 * @param {null || object} payload-
 * @returns  {object}  -
 */
export const wholesaleToggleConnectionRequestCompanyClientModal = (payload) => {
    return {
        type: WHOLESALE_TOGGLE_CONNECTION_REQUEST_COMPANY_CLIENT_MODAL,
        payload: payload
    }
}
/**
 * WholesaleConnectionClientsAC. модалка для подтверждения партнера
 *
 * @param {null || object} payload-
 * @returns  {object}  -
 */
export const wholesaleInitialValuesForConnectionRequestCompanyPageAC = (payload) => {
    return {
        type: WHOLESALE_INITIAL_VALUES_FOR_CONNECTION_REQUEST_COMPANY_PAGE,
        payload: payload
    }
}
/**
 * wholesaleOperationsWithProductGroupByFirmAC. Операции с группами товаров  фирмы
 *
 * @param {null || object} payload-
 * @param {null || object} payload.material_type_list- товары
 * @param {null || object} payload.exceptions - связь товара с партнером
 * @returns  {object}  -
 */
export const wholesaleOperationsWithProductGroupByFirmAC = (payload) => {
    return {
        type: WHOLESALE_OPERATIONS_WITH_PRODUCT_GROUP_FIRM,
        payload: payload
    }
}
/**
 * wholesaleGoodsStockRetailOfFirmDataAC.Товары  авторизованной фирмы фирмы
 *
 * @param {null || object} payload-
 * @returns  {object}  -
 */
export const wholesaleGoodsStockRetailOfFirmDataAC = (payload) => {
    return {
        type: WHOLESALE_GOODS_STOCK_RETAIL_OF_FIRM_DATA,
        payload: payload
    }
}
/**
 * wholesaleGoodsStockRetailOfFirmModalAC. модальное окно для добавление товара в продажу
 *
 * @param {null || object} payload-
 * @param {boolean} payload.isOpen-
 * @param {null || object} payload.goods-
 * @returns  {object}  -
 */
export const wholesaleGoodsStockRetailOfFirmAddModalAC = (payload) => {
    return {
        type: WHOLESALE_GOODS_STOCK_RETAIL_OF_FIRM_ADD_MODAL,
        payload: payload
    }
}

/**
 * wholesaleGoodsStockRetailOfFirmDataAC. настройки остатков для розничной и оптовой продажи (партнёром)
 *
 * @param {null || object} payload-
 * @returns  {object}  -
 */
export const wholesaleGoodsPartnerSetupDataAC = (payload) => {
    return {
        type: WHOLESALE_GOODS_PARTNER_SETUP_DATA,
        payload: payload
    }
}
/**
 * wholesaleGoodsStockRetailOfFirmDataAC. настройки остатков для розничной и оптовой продажи (партнёром)
 *
 * @param {null || object} payload-
 * @param {boolean} payload.isOpen-
 * @param {object || null } payload.goods - товар
 * @param {number || null } payload.firm_main - товар
 * @returns  {object}  -
 */
export const wholesaleGoodsPartnerSetupModalToggleAC = (payload) => {
    return {
        type: WHOLESALE_GOODS_PARTNER_SETUP_MODAL,
        payload: payload
    }
}

/**
 * wholesaleGoodsRetailPriceSetAC. настройки остатков для розничной и оптовой продажи (партнёром)
 *
 * @param {null || object} payload-
 * @returns  {object}  -
 */
export const wholesaleGoodsRetailPriceSetAC = (payload) => {
    return {
        type: WHOLESALE_GOODS_RETAIL_PRICE_SET,
        payload: payload
    }
}
/**
 * wholesaleGoodsRetailPriceReadModalAC.  Отобразить данные цены товара установленным оптовиком для firm_partner
 *
 * @param {null || object} payload-
 * @param {object || null } payload.goods - товар
 * @param {number || null } payload.retail_price -retail price of price
 * @returns  {object}  -
 */
export const wholesaleGoodsRetailPriceReadModalAC = (payload) => {
    return {
        type: WHOLESALE_GOODS_RETAIL_PRICE_READ_MODAL,
        payload: payload
    }
}/**
 * wholesaleGoodsRetailPriceReadModalAC. Оптовик может установить цену для конкретного клиента, указав firm_partner
 *
 * @param {null || object} payload-
 * @param {object || null } payload.goods - товар
 * @param {object || null } payload.goods_retail_price - список с установкой цену для конкретного клиента
 * @returns  {object}  -
 */
export const wholesaleGoodsRetailPriceAddModalAC = (payload) => {
    return {
        type: WHOLESALE_GOODS_RETAIL_PRICE_ADD_MODAL,
        payload: payload
    }
}