import {instances, URI_API_EXTTUNELL} from "../Salary/setting_api";
import {rejectTransform, resolveTransform} from "../utils_errors";

const ws_goods_partner_setup_set = 'ws_goods_partner_setup_set'
const ws_goods_partner_setup_delete = 'ws_goods_partner_setup_delete'
const ws_goods_partner_setup_get = 'ws_goods_partner_setup_get'

/**
 * WholesaleGoodsPartnerSetupAPI. апи настройки остатков для розничной и оптовой продажи (партнёром)
 *
 * @param {}  -
 * @param {}  -
 * @returns  {}  -
 */
export const WholesaleGoodsPartnerSetupAPI={
    getGoodsPartnerSetUp() {
        return instances.post(URI_API_EXTTUNELL + '/', null, {
            headers: {
                action: ws_goods_partner_setup_get,
            },
        }).then(res => resolveTransform(res))
            .catch(error => rejectTransform(error))
    },
    setGoodsPartnerSetUp(data){
        return instances.post(URI_API_EXTTUNELL + '/', data, {
            headers: {
                action: ws_goods_partner_setup_set,
            },
        }).then(res => resolveTransform(res))
            .catch(error => rejectTransform(error))
    } ,
    deleteGoodsPartnerSetUp(data){
        return instances.post(URI_API_EXTTUNELL + '/', data, {
            headers: {
                action: ws_goods_partner_setup_delete,
            },
        }).then(res => resolveTransform(res))
            .catch(error => rejectTransform(error))
    }
}